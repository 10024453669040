import React from 'react';
import { useNavigate } from 'react-router-dom';
import images from 'src/assets/images';

const Notifications: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/settings'); 
  };


  return (
    <div className="relative h-screen overflow-hidden">
      <div className="absolute top-6 flex justify-between items-center w-full px-4">
        <img
          src={images.leftarrow}
          alt="Back"
          className="w-4 h-4 cursor-pointer mt-3"
          onClick={handleBack}
        />
        <div className="flex-grow flex justify-center">
          <h1 className="text-lg font-bold mt-2">Notifications</h1>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
