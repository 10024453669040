import React from 'react';
import images from 'src/assets/images';

interface Step {
  number: number;
  heading: string;
  description: string;
  completed?: boolean;
}

interface RedemptionStepsProps {
  steps: Step[];
}

const RedemptionSteps: React.FC<RedemptionStepsProps> = ({ steps }) => {
  return (
    <div className="w-full rounded-2xl p-4">
      <h3 className="text-sm text-white text-opacity-50 mb-2">REDEMPTION INSTRUCTIONS</h3>
      <hr className="border-[#FFFFFF] border-opacity-30 mb-4" />

      <ul className="space-y-4">
        {steps.map((step, index) => (
          <li key={index} className="relative flex items-start space-x-4">
            <div className="flex flex-col items-center">
              <div
                className={`w-6 h-6 rounded-full flex items-center justify-center ${
                  step.completed ? 'bg-transparent' : 'bg-[#3D86FF]'
                } text-white font-bold text-sm`}
              >
                {step.completed ? (
                  <img src={images.success_icon_2} alt="Check" className="w-full h-full" />
                ) : (
                  step.number
                )}
              </div>
            </div>

            <div className="ml-3 flex flex-col">
              <h4
                className={`text-sm font-bold mb-1 ${
                  step.completed
                    ? 'text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400'
                    : 'text-[#3D86FF]'
                }`}
              >
                {step.heading}
              </h4>
              <p
                className={`text-sm font-normal mb-1 ${
                  step.completed ? 'text-[#FFFFFF] text-opacity-50' : 'text-[#FFFFFF]'
                }`}
              >
                {step.description}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RedemptionSteps;
