import React, { useState, useEffect } from 'react';
import Button from 'src/components/common/button';
import { InputField } from 'src/components/common/inputField';
import images from 'src/assets/images';
import { useAuth } from 'src/contexts/authContext';

interface CustomDonationProps {
  onClose: () => void;
  onComplete: (amount: number) => void;
}

const CustomDonation: React.FC<CustomDonationProps> = ({ onClose, onComplete }) => {
  const { donationAmount, setDonationAmount } = useAuth();
  const [customDonationAmount, setCustomDonationAmount] = useState<string>('');

  useEffect(() => {
    if (donationAmount) {
      setCustomDonationAmount(donationAmount.toString());
    }
  }, [donationAmount]);

  const handleDonationChange = (e: any) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    setCustomDonationAmount(value);

    if (value === '' || parseInt(value, 10) === 0) {
      setDonationAmount(0);
    }
  };

  const handleSubmit = () => {
    const amount = parseInt(customDonationAmount, 10);

    if (!isNaN(amount) && amount > 0) {
      onComplete(amount);
      onClose();
    } else {
      setDonationAmount(0);
      onClose();
    }
  };

  const handleClose = () => {
    if (!customDonationAmount || parseInt(customDonationAmount, 10) === 0) {
      setDonationAmount(0);
    }
    onClose();
  };

  return (
    <div className="w-auto">
      <div className="absolute inset-0 z-10 bg-[#0A1E32] bg-opacity-80 max-w-[548px] mx-auto" style={{ backdropFilter: 'blur(2px)' }} />
      <div className="fixed inset-x-0 bottom-0 flex flex-col items-center justify-center bg-white rounded-t-3xl z-40 h-[calc(50%-5rem)] max-w-[548px] w-full mx-auto p-6">
        <button onClick={handleClose} className="absolute top-4 right-4 flex items-center justify-center w-8 h-8 bg-gradient-to-r from-pink-500 to-orange-400 rounded-full">
          <img src={images.close_icon} alt="Close" className="w-4 h-4" />
        </button>

        <div className="flex flex-col items-center justify-center w-full">
          <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">Enter Custom Amount</h2>
          <p className="text-md mb-4 text-[#1E1E1E] text-center">
            Please specify the amount you'd like to donate.
          </p>
          <div className="relative w-full">
            <InputField
            type="text"
            placeholder="Enter amount"
            value={customDonationAmount}
            onChange={handleDonationChange}
            prefix="$"
            />
          </div>
          <Button onClick={handleSubmit} disabled={!customDonationAmount || parseInt(customDonationAmount, 10) === 0}>
            Donate
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomDonation;
