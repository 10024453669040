import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Button from 'src/components/common/button';
import { useAuth } from 'src/contexts/authContext';

interface AppDonationThreeProps {
  onClose: () => void;
}

const AppDonationThree: React.FC<AppDonationThreeProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setAppDonationAmount } = useAuth();

  const handleSubmit = () => {
    const isUpdate = searchParams.get('update')
    console.log(isUpdate, 'navigate(\'/cause-payment-receipt\');')
    setAppDonationAmount(0);
    onClose();
    if(!!isUpdate) {
      navigate('/cause-payment-receipt?update=true');
    } else {
      navigate('/cause-payment-receipt');
    }
  };

  return (
    <div className="w-auto">
      <div className="fixed inset-x-0 z-10 bg-transparent h-[calc(90%-2rem)] max-w-[548px] mx-auto"  />
      <div className="fixed inset-x-0 bottom-0 bg-[#0A1E32] bg-opacity-100 rounded-t-3xl z-40 h-[calc(95%-2rem)] max-w-[548px] w-full mx-auto p-4 sm:p-6" style={{ backdropFilter: 'blur(20px)' }}>
        <div className="p-8 w-full">
          <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            That's ok! We're just glad you're here :)
          </h2>
          <p className="text-md mb-4 text-white">
            Now let’s finish your donation to the cause you want to support.
          </p>
          <div className="absolute bottom-9 left-10 w-10/12">
            <Button onClick={handleSubmit}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDonationThree;
