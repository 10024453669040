import React, { useState, forwardRef, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import images from 'src/assets/images';

interface InputFieldProps {
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  prefix?: string;
  maxLength?: number;
}

// Using forwardRef to support auto-focus functionality
const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ type, placeholder, value, onChange, prefix, maxLength }, ref) => {
    const [inputType, setInputType] = useState(type);

    const togglePasswordVisibility = () => {
      setInputType(inputType === 'password' ? 'text' : 'password');
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
      if (typeof e === 'string') {
        onChange(e); // For PhoneInput
      } else {
        onChange(e); // For regular input fields
      }
    };

    // Render PhoneInput if the type is "phone"
    if (type === 'phone') {
      return (
        <div className="relative w-full mb-6">
          <PhoneInput
            country={'us'}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            inputClass="text-xl"
          />
        </div>
      );
    }

    return (
      <div className="relative w-full mb-6">
        {prefix && (
          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
            {prefix}
          </span>
        )}
        <input
          ref={ref} // Forwarded ref for auto-focus support
          type={inputType}
          placeholder={placeholder}
          className={`w-full border-2 rounded-input-custom py-2 px-4 text-xl text-gray-500 bg-[rgba(33,45,54,0.06)] border-transparent outline-none focus:border-[#3D86FF] ${prefix ? 'pl-10' : ''}`}
          value={value}
          onChange={handleChange}
          maxLength={maxLength} // Apply maxLength only if specified
        />
        {type === 'password' && (
          <img
            src={inputType === 'password' ? images.hide : images.show}
            alt="Toggle password visibility"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer w-7 h-7"
            onClick={togglePasswordVisibility}
          />
        )}
      </div>
    );
  }
);

interface OTPInputProps {
  length: number;
  value: string;
  onChange: (value: string) => void;
  onComplete: () => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ length, value, onChange, onComplete }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Auto-focus the input field on component mount
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (value.length === length) {
      onComplete();
    }
  }, [value, length, onComplete]);

  const handleChange = (e: any) => {
    const newValue = e.target.value.slice(0, length); // Limit input to specified length
    onChange(newValue);
  };

  return (
    <div className="flex justify-center">
      <InputField
        ref={inputRef} // Attach ref for auto-focus
        type="number"
        placeholder="Enter OTP"
        value={value}
        onChange={handleChange}
        maxLength={length} // Set maxLength to the OTP length
      />
    </div>
  );
};

export { InputField,OTPInput };