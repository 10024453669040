import React, { useEffect, useState } from 'react';
import BottomTabsBar from 'src/components/common/bottomTabsBar';
import ReferralStatsCard from 'src/components/refer/referralStatsCard';
import InviteFriendsCard from 'src/components/refer/inviteFriendsCard';
import ReferredUserCard from 'src/components/refer/referredUserCard';
import images from 'src/assets/images';
import { useAuth } from 'src/contexts/authContext';
import { fetchUserReferrals, fetchUsersProfile } from 'src/services/refer/api';

const Refer: React.FC = () => {
  const { user } = useAuth();
  
  // State to store referrals data and profile images
  const [referralStats, setReferralStats] = useState({
    totalReferrals: 0,
    totalDonations: 0,
    referredUsers: [],
  });
  const [profiles, setProfiles] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileResponse: any = await fetchUsersProfile();
        setProfiles(profileResponse.profiles.map((user: any) => user.profileUrl));
        const response: any = await fetchUserReferrals({ userId: user.id });
        setReferralStats({
          totalReferrals: response.referredUsers.length,
          totalDonations: response.totalDonations,
          referredUsers: response.referredUsers,
        });
      } catch (error) {
        console.error('Failed to fetch referral data:', error);
      }
    };

    fetchData();
  }, [user.id]);

  return (
    <>
      <div className="px-4 mt-4">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Referrals</h1>
        </div>
      </div>

      <div className="flex-grow px-4 overflow-y-auto overflow-x-hidden scrollbar-hide pb-20">
        <div className="mt-4 space-y-4">
          <ReferralStatsCard
            totalReferrals={referralStats.totalReferrals}
            totalDonations={referralStats.totalDonations}
          />
        </div>

        <div className="mt-8 w-full">
          <h2 className="text-lg font-bold text-gray-900 ml-2">Share Link</h2>
          <div className="mt-2">
            <InviteFriendsCard referralCode={user.referralCode} profiles={profiles} />
          </div>
        </div>

        <div className="mt-8 w-full">
          <h2 className="text-lg font-bold text-gray-900 ml-2">My Referrals</h2>
          <div className="mt-2 space-y-2">
            {referralStats.referredUsers.length > 0 ? (
              referralStats.referredUsers.map((referredUser: any, index: number) => (
                <ReferredUserCard
                  key={index}
                  name={referredUser.referredUserName}
                  donation={`$${referredUser.subscriptionAmount}`}
                  avatarUrl={referredUser.causeProfileUrl || images.cause_logo}
                />
              ))
            ) : (
              <p className="text-center text-[#787B7E] mt-10">No referrals found</p>
            )}
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto">
        <BottomTabsBar />
      </div>
    </>
  );
};

export default Refer;
