import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const UserReferralSignup: React.FC = () => {
  const location = useLocation(); 
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get('referralCode');

      if (referralCode) {
      navigate('/introduction-one', { state: { referralCode } });
    } 
  }, [location]);

  return null; 
};

export default UserReferralSignup;
