import { get, post } from "../request";
import { API_URLS } from "src/constants/apiUrls";

export const fetchRewards = async () => {
  try {
    const data = await get(API_URLS.fetchRewards);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchReward = async (param: any) => {
  try {
    const data = await post(API_URLS.fetchReward, param);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getReward = async (param: any) => {
  try {
    const data = await post(API_URLS.getReward, param);
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkRewardAvailed = async (param: any) => {
  try {
    const data = await post(API_URLS.checkRewardAvailed, param);
    return data;
  } catch (error) {
    throw error;
  }
};