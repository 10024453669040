import React, { useEffect } from 'react';
import Loader from 'src/components/common/loader';
import images from 'src/assets/images';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyOtp } from '../functions/sendOtp';
import { signupUser } from 'src/services/auth/api';
import { useAuth } from 'src/contexts/authContext';
import useRedirectIfNoState from 'src/hooks/redirectHook';
import { toast } from 'react-toastify';

const LoadingVerificationScreen: React.FC = () => {
  const navigate = useNavigate();
  const { signup } = useAuth();
  const location = useLocation();
  const { email, name, phoneNumber, password, otp } = location.state as { email: string, name: string, phoneNumber: string, password: string, otp: string } || {};
  
  useRedirectIfNoState('email' || 'name' || 'phoneNumber' || 'password' || 'otp');

  const handleBack = () => {
    navigate('/sms-verification', { state: { email, name, phoneNumber, password } }); 
  };

  useEffect(() => {
    const verifyAndSignup = async () => {
      try {
        const idToken = await verifyOtp(otp);
        if (idToken === null) {
          toast.error('Incorrect OTP. Please try again.');
          return;
        }
        
        console.log("idToken", idToken);
        const referralUserId = localStorage.getItem('referralUserId');
        const referralCauseId = localStorage.getItem('referralCauseId');
        const userData: any = {
          email,
          name,
          phone: phoneNumber,
          password,
          idToken
        };

        if (referralUserId) {
          userData.referralUserId = referralUserId; 
        }
        
        if (referralCauseId) {
          userData.referralCauseId = referralCauseId;
        }

        const user = await signupUser(userData);

        if (user) {
          signup(user); 
        }
      } catch (error) {
        console.error('Error during verification and signup:', error);
        navigate('/sms-verification', { state: { email, name, phoneNumber, password } });
      }
    };

    verifyAndSignup();
  }, [otp, email, name, phoneNumber, password, signup, navigate]);

  return (
    <>
      <div className="absolute top-6 left-4">
        <img src={images.leftarrow} alt="Back" className="w-4 h-4 cursor-pointer mt-3" onClick={handleBack} />
      </div>
      <div className="flex flex-col items-center justify-center flex-1 mt-[-150px]">
        <div className="loader-wrapper">
          <Loader />
        </div>
        <p className="text-gray-600 mt-20">Verifying your code...</p>
      </div>
    </>
  );
};

export default LoadingVerificationScreen;
