import React, { useState, useEffect } from 'react';
import images from 'src/assets/images';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { fetchUserByReferralCode } from 'src/services/auth/api';
import { toast } from 'react-toastify';

const IntroScreenOne: React.FC = () => {
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [referralUserId, setReferralUserId] = useState<number | null>(null);
  const [referralCauseId, setReferralCauseId] = useState<number | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const state = location.state as { referralCode?: string };
    if (state?.referralCode) {
      setReferralCode(state.referralCode);
      fetchReferralUserId(state.referralCode);
    }
  }, [location]);

  const fetchReferralUserId = async (code: string) => {
    try {
      const response: any = await fetchUserByReferralCode({ referralCode: code });
      if (response) {
        if (response.userId) {
          setReferralUserId(response.userId);
          localStorage.setItem('referralUserId', response.userId);
          localStorage.removeItem('referralCauseId');
        } else if (response.causeId) {
          setReferralCauseId(response.causeId);
          localStorage.setItem('referralCauseId', response.causeId);
          localStorage.removeItem('referralUserId');
        } else {
          resetReferralData();
        }
      }
    } catch (error) {
      console.error('Error fetching referral user ID:', error);
      resetReferralData();
      toast.error('Invalid referral code.');
    }
  };

  const resetReferralData = () => {
    setReferralUserId(null);
    setReferralCauseId(null);
    localStorage.removeItem('referralUserId');
    localStorage.removeItem('referralCauseId');
  };

  const handleNext = () => {
    navigate('/introduction-two');
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div {...handlers} className="relative w-full h-full flex flex-col items-center justify-center p-4 bg-darkblue">
      <div
        className="absolute inset-0 bg-cover bg-center rounded-3xl"
        style={{ backgroundImage: `url(${images.intro_one})` }}
      ></div>
      <div className="absolute bottom-[120px] z-10 flex flex-col items-center text-center px-6">
        <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
          We Believe Good People Deserve<br />
          <span className="block">To Be Rewarded...</span>
        </h1>
        <p className="text-s mb-10" style={{ color: '#8197AD' }}>
          Join our community of fundr’s who are changing the world and getting rewarded<br />
          <span className="block">for their donations.</span>
        </p>
        <div className="fixed bottom-0 z-10 w-full max-w-[86vw] sm:max-w-[500px] mx-auto px-4 py-4 shadow-lg">
          <button
            className="relative py-0.5 px-0.5 rounded-custom text-white bg-transparent border-none mt-6 w-full max-w-md"
            onClick={handleNext}
          >
            <span className="absolute inset-0 bg-gradient-to-r from-pink-500 to-orange-400 rounded-custom p-px"></span>
            <span className="relative bg-darkblue rounded-custom block py-3 px-12 font-bold text-lg">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntroScreenOne;
