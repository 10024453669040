import React from 'react';
import images from 'src/assets/images';

interface LegalLinksProps {
  links: { name: string; onClick: () => void }[];
}

const LegalLinks: React.FC<LegalLinksProps> = ({ links }) => {
  return (
    <div className="mt-4 bg-[#F2F2F3] rounded-xl">
      {links.map((link, index) => (
        <div
          key={index}
          onClick={link.onClick}
          className={`flex justify-between items-center py-4 px-4 cursor-pointer transition-all duration-200 hover:bg-[#E8ECEE] ${
            index !== links.length - 1 ? 'border-b border-[#E8ECEE]' : ''
          }`}
        >
          <span className="text-gray-700">{link.name}</span>
          <img src={images.right_direction_icon} alt="Go to setting" className="w-4 h-4" />
        </div>
      ))}
    </div>
  );
};

export default LegalLinks;
