import React from 'react';

interface UserRewardCardProps {
  imageUrl: string;
  category: string;
  title: string;
  onClick: () => void;
}

const UserRewardCard: React.FC<UserRewardCardProps> = ({ imageUrl, category, title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="ticket-card rounded-xl overflow-hidden h-72 w-82 bg-white relative"
    >
      <div className="relative flex-1 flex items-center justify-center overflow-hidden rounded-t-2xl">
        <img
          src={imageUrl}
          alt={title}
          className="w-full max-h-36 object-cover"
        />
      </div>
      <div className="px-4 py-2" style={{ minHeight: '120px' }}>
        <h3
          className="text-md"
          style={{
            background: 'linear-gradient(to bottom, #EC4899, #FB923C)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {category}
        </h3>
        <h1 className="text-xl font-bold text-gray-900 line-clamp-2">{title}</h1>
        <button
          className="mt-2 w-full text-white py-2 rounded-xl"
          style={{
            background: 'linear-gradient(to right, #EC4899, #FB923C)',
          }}
        >
          VIEW REWARD DETAILS
        </button>
      </div>
    </div>
  );
};

export default UserRewardCard;
