import {useNavigate} from "react-router-dom";
import images from "../assets/images";
import React from "react";

const HelpPage = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-gray-100 text-gray-800 min-h-screen p-6 max-h-full overflow-y-auto">
            <div className="max-w-4xl mx-auto p-2 py-8">
                <button
                    onClick={() => navigate(-1)}
                    className="absolute top-6 left-6 z-20 bg-white bg-opacity-60 text-gray-900 p-3 rounded-full shadow-md hover:bg-gray-200 focus:outline-none"
                >
                    <img src={images.leftarrow} alt="Back" className="w-3 h-3"/>
                </button>
                <div className="h-full min-h-[60vh] flex flex-col items-center justify-center">
                    <img src={images.help} className="w-[300px] h-[300px] rotate-[180deg] drop-shadow-2xl" alt="Help"/>

                    <div
                        className="w-full mt-10 p-4 text-center bg-white rounded-lg shadow-2xl sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex p-2 items-center">
                            <div className="w-1/4">
                                <img src={images.email_icon} className="w-12 w-12"/>
                            </div>
                            <h2 className="flex-1 text-left ">found an issue. reach us at <a
                                className="text-blue mt-[50px] visited:text-blue decoration-0"
                                href="mailto:fundrteam@gmail.com">fundrteam@gmail.com</a></h2>
                        </div>

                    </div>
                    <a href="/"
                        className="w-full mt-10 p-4 text-center bg-white rounded-lg shadow-2xl sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex p-2 items-center">
                            <div className="w-1/4">
                                <img src={images.back_to_app} className="w-12 w-12"/>
                            </div>
                            <h2 className="flex-1 text-left ">Back to donations
                            </h2>
                        </div>

                    </a>
                </div>
            </div>
        </div>
    )
}
export default HelpPage
