import React, { useState } from 'react';
import images from 'src/assets/images';

interface InviteFriendsCardProps {
  referralCode: string;
  profiles: string[]; // Array of profile image URLs
}

const clientUrl = process.env.REACT_APP_CLIENT_URL || 'https://fundrgiving.com';

const InviteFriendsCard: React.FC<InviteFriendsCardProps> = ({ referralCode, profiles }) => {
  const [buttonText, setButtonText] = useState<string>('Copy');
  const inviteLink = `${clientUrl}/signup?referralCode=${referralCode}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLink);
    setButtonText('Copied!');

    setTimeout(() => {
      setButtonText('Copy');
    }, 5000);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Join Fundr!',
          text: 'Hey! I just started using this app called Fundr—it rewards you for supporting the causes you care about. Every donation earns you Fundcoin, which you can spend on deals and discounts within the app. Plus, if you join with my link, you’ll get 10 extra Fundcoin! Go check it out and make a difference with me!',
          url: inviteLink,
        });
        console.log('Successful share');
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  return (
    <div
      className="w-full rounded-2xl p-6 flex flex-col items-center text-center"
      style={{
        backgroundImage: `url(${images.share_link_card_Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '440px',
        color: '#ffffff',
      }}
    >
      {/* Avatars */}
      <div className="mt-6 flex items-center justify-center mb-4">
        {profiles.slice(1, 4).map((profile, index) => (
          <img
            key={index}
            src={profile || images.profile} // Fallback to default profile if URL is missing
            alt={`Friend ${index + 1}`}
            className={`rounded-full border-2 border-white ${
              index === 1 ? 'w-14 h-14 z-20' : 'w-10 h-10 z-10'
            } ${index === 0 ? '-mr-4' : index === 2 ? '-ml-4' : ''}`}
          />
        ))}
      </div>

      {/* Title */}
      <h2
        className="text-4xl font-bold mb-2"
        style={{
          background: 'linear-gradient(to right, #EC4899, #FB923C)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Together is more fun!
      </h2>

      {/* Description */}
      <p className="text-base mb-6">
        Invite friends and get fundcoin!{' '}
        <span className="text-[#6886A4]">When your friends join Fundr,</span> both of you will get 10 fundcoin.
      </p>

      {/* Invite Button */}
      <button
        onClick={handleShare}
        className="w-full bg-gradient-to-r from-pink-500 to-orange-400 text-white text-lg py-3 rounded-lg font-semibold flex items-center justify-center mb-6"
      >
        <img src={images.more_icon} alt="Share" className="w-5 h-5 mr-2" />
        Invite Your Friends
      </button>

      {/* Invite Link with Copy Button */}
      <div className="flex items-center justify-between bg-[#102a43] rounded-md px-4 py-3 w-full">
        <span className="text-white text-sm truncate">{inviteLink}</span>
        <button
          className="bg-[#0A1E32] text-white px-4 py-1 rounded-md font-semibold"
          onClick={handleCopy}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default InviteFriendsCard;
