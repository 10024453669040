import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC52G16f4v1MOxnxI4Cd_Qf8hKoRyNH5mQ",
  authDomain: "fundr-c79e1.firebaseapp.com",
  projectId: "fundr-c79e1",
  storageBucket: "fundr-c79e1.appspot.com",
  messagingSenderId: "619794887374",
  appId: "1:619794887374:web:ef47697adc9ffeffb8d69f",
  measurementId: "G-GV0R5H6CYP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app);

auth.useDeviceLanguage();

export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
      });
      return token;
    }
    return null;
  } catch (error) {
    console.error('Notification permission error:', error);
    return null;
  }
};

export const onFcmTokenRefresh = (callback: (token: string) => void) => {
  return onMessage(messaging, () => {
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
    }).then(callback);
  });
};

export { app, auth, messaging, RecaptchaVerifier, signInWithPhoneNumber };
