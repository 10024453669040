import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/contexts/authContext';
import ProfileHeader from 'src/components/setting/profileHeader';
import AccountSettings from 'src/components/setting/accountSetting';
import LegalLinks from 'src/components/setting/legalLinks';
import LogoutButton from 'src/components/setting/logoutButton';
import images from 'src/assets/images';

const Settings: React.FC = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const accountSettings = [
    { name: 'Notifications', onClick: () => {navigate('/notifications')} },
    { name: 'Donation History', onClick: () => {navigate('/donation-history')} },
    { name: 'Change Donations', onClick: () => {navigate('/change-donation')} },
    { name: 'Help', onClick: () => {navigate('/help')} },
  ];

  const legalLinks = [
    { name: 'Privacy Policy', onClick: () => {navigate('/privacy')} },
    { name: 'Terms of Service', onClick: () => {navigate('/terms')} },
  ];

  const handleClose = () => {
    navigate('/dashboard');
  };

  return (
    <div className="p-4 mt-4">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold ml-4">Settings</h1>
        <svg
    xmlns={images.close_icon}
     className="w-8 h-8 cursor-pointer"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    onClick={handleClose}
    style={{ color: '#0A1E32', opacity: 0.3 }}
  >
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </svg>
      </div>

      <div className="mt-4">
        <ProfileHeader
          profileImage={user.profileUrl || images.profile}
          firstName={user.firstName}
          lastName={user.lastName}
        />
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-bold">Account Settings</h2>
        <AccountSettings settings={accountSettings} />
      </div>

      <LegalLinks links={legalLinks} />
      <LogoutButton onLogout={logout} />
    </div>
  );
};

export default Settings;
