import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

interface MediaGalleryProps {
  mainImage: string;
  additionalImages: string[];
  videoUrl?: string;
  altText?: string;
}

const MediaGallery: React.FC<MediaGalleryProps> = ({
  mainImage,
  additionalImages,
  videoUrl,
  altText = "Media",
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVideo, setIsVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const allMedia = videoUrl ? [mainImage, ...additionalImages, videoUrl] : [mainImage, ...additionalImages];

  const handleMediaSelect = (index: number) => {
    setCurrentIndex(index);
    setIsVideo(index === allMedia.length - 1 && videoUrl ? true : false);
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % allMedia.length;
    handleMediaSelect(nextIndex);
  };

  const handlePrevious = () => {
    const prevIndex = (currentIndex - 1 + allMedia.length) % allMedia.length;
    handleMediaSelect(prevIndex);
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  // Configure swipe handlers
  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
  });

  return (
    <div className="w-full relative" {...swipeHandlers}>
      <div className="relative w-full h-[300px] rounded-lg overflow-hidden mb-4">
        {isVideo ? (
          <video
            src={videoUrl}
            className="w-full h-full object-cover"
            controls
            autoPlay
          />
        ) : (
          <img
            src={allMedia[currentIndex]}
            alt={altText}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              isLoading ? 'opacity-0' : 'opacity-100'
            }`}
            onLoad={handleImageLoad}
          />
        )}
      </div>

      {/* Dots Indicator */}
      <div className="flex justify-center space-x-2 mt-2 mb-2">
        {allMedia.map((_, index) => (
          <div
            key={index}
            onClick={() => handleMediaSelect(index)}
            className={`w-3 h-3 rounded-full cursor-pointer ${
              index === currentIndex ? 'bg-[#000000]' : 'bg-[#9DA8B8]'
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default MediaGallery;
