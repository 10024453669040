import React, {useState, useEffect} from 'react';
import TopBar from 'src/components/dashboard/topbar';
import DonationProgressBar from 'src/components/dashboard/donationProgressBar';
import FundcoinBalanceCard from 'src/components/dashboard/fundBalanceCard';
import ReferCard from 'src/components/dashboard/referCard';
import BottomTabsBar from 'src/components/common/bottomTabsBar';
import HorizontalScroll from 'src/components/common/horizontalScroll';
import UserRewardCard from 'src/components/dashboard/userRewardCard';
import PopularRewardCard from 'src/components/reward/popularRewardCard';
import SliderWrapper from 'src/components/slider/sliderWrapper';
// import WelcomeBackScreen from './welcomeBack';
import {fetchUserProfile, fetchPopularRewards, claimHomescreenReward} from 'src/services/dashboard/api';
import {useAuth} from 'src/contexts/authContext';
import UserCauseCard from 'src/components/dashboard/userCauseCard';
import {useNavigate, useLocation} from 'react-router-dom';
import Images from "../../../assets/images";
import { toast } from 'react-hot-toast';

type Cause = {
    id: string;
    name: string;
    goalAmount: number;
    currentAmount: number;
    causeProfileUrl: string;
    image1Url: string;
};

type Reward = {
    name: string;
    fundcoinCost: number;
    image1Url: string;
    RewardCategory: {
        name: string;
    };
};

type PopularReward = {
    name: string;
    fundcoinCost: number;
    image1Url: string;
    rewardId: number;
    CategoryName: string;
    buyersCount: number;
    profilePictures: string[];
    color: string;
};

const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {user, updateUser} = useAuth();
    // const [showWelcomeBack, setShowWelcomeBack] = useState(false);
    const [causes, setCauses] = useState<Cause[]>([]);
    const [totalDonations, setTotalDonations] = useState(0);
    const [yearlyGoal, setYearlyGoal] = useState(0);
    const [fundCoinBalance, setFundCoinBalance] = useState(0);
    const [installIcon, setInstallIcon] = useState<boolean>(false);
    const [rewards, setRewards] = useState<Reward[]>([]);
    const [popularRewards, setPopularRewards] = useState<PopularReward[]>([]);
    useEffect(() => {
        handleShowInstallIcon();
    }, []);
    const handleShowInstallIcon = async () => {
        const shouldShow = await localStorage.getItem('showInstallIcon');
        if (shouldShow !== 'false') {
            setTimeout(() => setInstallIcon(true), 3000);
        }
    }
    const handleCloseInstallIcon = () => {
        localStorage.setItem('showInstallIcon', 'false');
        setInstallIcon(false);
    }
    const fetchData = async () => {
        try {
            const userProfileData: any = await fetchUserProfile({userId: user.id});
            await updateUser({userId: user.id});

            setCauses(userProfileData.causes || []);
            setTotalDonations(userProfileData.totalDonations || 0);
            setYearlyGoal(userProfileData.yearlyGoal || 0)
            setFundCoinBalance(user.fundcoinBalance || 0);
            setRewards(userProfileData.rewards || []);

            const popularRewardsData: any = await fetchPopularRewards();
            setPopularRewards(popularRewardsData);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');

        // if (!hasSeenWelcome) {
        //     setShowWelcomeBack(true);
        //     localStorage.setItem('hasSeenWelcome', 'true');
        // }

        fetchData();
    }, [user.id, location]);

    const handleCauseClick = (causeId: string) => {
        navigate(`/cause-detail/${causeId}`);
    };

    const handleRewardClick = (rewardId: string) => {
        navigate(`/reward-detail/${rewardId}`);
    };

    const handleHomeScreenReward = async () => {
        try {
            // Call backend API to add fundcoin reward
            const response: any = await claimHomescreenReward({ userId: user.id });
            
            if (response.success) {
                // Update user's balance in context
                if (response.newBalance) {
                    await updateUser({ userId: user.id });
                }
                
                // Close popup and prevent it from showing again
                localStorage.setItem('showInstallIcon', 'false');
                setInstallIcon(false);
                
                // Show success message
                toast.success('Congratulations! 10 fundcoin added to your wallet');
            }
        } catch (error: any) {
            console.error('Failed to add homescreen reward:', error);
            // Show specific error message if available
            toast.error(error.message || 'Failed to add reward. Please try again.');
        }
    };

    return (
        <>
            <div className="fixed top-0 z-10 w-full max-w-[548px] mx-auto">
                <TopBar profileImage={user.profileUrl}/>
            </div>

            <div
                className="flex flex-col items-center justify-start px-4 sm:px-6 mt-20 sm:mt-6 flex-grow overflow-y-auto overflow-x-hidden scrollbar-hide w-full">
                <DonationProgressBar currentAmount={totalDonations} yearlyGoal={yearlyGoal || 150}/>
                <div className="-mt-4 space-y-4 flex flex-col items-center w-full">
                    <FundcoinBalanceCard balance={fundCoinBalance}/>
                    <div className="mt-2 w-full">
                        <ReferCard/>
                    </div>
                </div>

                <div className="mt-4 w-full">
                    <h2 className="text-lg font-semibold text-gray-900 ml-2">My Causes</h2>
                    {causes.length > 0 ? (
                        <HorizontalScroll>
                            {causes.map((cause, index) => (
                                <UserCauseCard
                                    key={index}
                                    causeWallPaper={cause.image1Url}
                                    causeLogo={cause.causeProfileUrl}
                                    causeName={cause.name}
                                    raisedAmount={cause.currentAmount}
                                    goalAmount={cause.goalAmount}
                                    onClick={() => handleCauseClick(cause.id)}
                                />
                            ))}
                        </HorizontalScroll>
                    ) : (
                        <p className="text-center text-[#787B7E] mt-10">No causes available</p>
                    )}
                </div>

                {rewards.length > 0 && (
                    <div className="mt-4 w-full">
                        <h2 className="text-lg font-bold text-gray-900 ml-2">My Rewards</h2>
                        <div className="relative rounded-xl w-full  shadow-md shadow-[0px_4px_10px_rgba(0,0,0,0.2)]">
                            <SliderWrapper
                                items={rewards}
                                renderItem={(reward, index) => (
                                    <UserRewardCard
                                        key={index}
                                        imageUrl={reward.image1Url}
                                        category={reward.RewardCategory.name}
                                        title={reward.name}
                                        onClick={() => handleRewardClick(reward.id)}
                                    />
                                )}
                            />
                        </div>
                    </div>
                )}

                <div className="mt-12 w-full">
                    <h2 className="text-lg font-bold text-gray-900 ml-2">Popular Rewards</h2>
                    <div className="relative rounded-xl w-full">
                        {popularRewards.length > 0 ? (
                            <SliderWrapper
                                items={popularRewards}
                                renderItem={(reward, index) => (
                                    <PopularRewardCard
                                        key={index}
                                        imageUrl={reward.image1Url}
                                        category={reward.CategoryName}
                                        title={reward.name}
                                        coinAmount={reward.fundcoinCost}
                                        buyersCount={reward.buyersCount || 0}
                                        buyersImages={reward.profilePictures || []}
                                        backgroundColor={reward.color || '#00BCD5'}
                                        onClick={() => handleRewardClick(reward.id)}
                                    />
                                )}
                            />
                        ) : (
                            <p className="text-center text-[#787B7E] mt-10">No popular rewards available</p>
                        )}
                    </div>
                </div>

                <div className="mt-12 w-full">
                    {/* <h2 className="text-lg font-bold text-gray-900 ml-2 mb-10">My Donations</h2> */}
                    <div className="w-full h-40 bg-gray-100 rounded-lg"></div>
                </div>
            </div>

            <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto">
                <BottomTabsBar/>
            </div>
            {installIcon && <>
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="bg-[#0A1E32] rounded-xl p-6 w-[356px] text-center text-white relative">
                <button
                className="absolute top-3 right-3 text-xl text-white"
                onClick={() => handleCloseInstallIcon()}
                >
                <img src={Images.closeIcon} alt="Close" className="w-4 h-4" />
                </button>
                <div className="flex justify-center mb-5">
                <img
                    src={Images.fundr_logo}
                    alt="Fundr Icon"
                    className="w-32 h-32 absolute left-[50%] top-8 translate-x-[-50%]"
                />
                </div>
                <h2 className="text-[40px] mt-[120px] font-semibold mb-3 gradient-custom leading-[1]">
                Add to your home screen
                </h2>
                <p className="text-sm mb-6 text-[16px]">
                Add this app to your home screen just like a normal app{" "}
                <span className="text-[#6886A4]">for a better experience and get</span>{" "}
                <span className="text-[16px]">10 fundcoin</span>
                </p>
                <button className="flex items-center justify-center rounded-lg py-2 w-full text-white text-sm relative w-full h-16 rounded-2xl bg-transparent from-pink-500 to-orange-400 px-4 py-2 flex items-center">
                <span className="mr-2 text-lg"></span> Tap &nbsp;
                <img src={Images.install_icon} alt="Install icon" />
                &nbsp;then 'Add to Home Screen'
                </button>
                {/* New Button */}
                <button 
                    className="relative py-0.5 px-0.5 rounded-custom text-white bg-transparent border-none w-full max-w-md"
                    onClick={handleHomeScreenReward}
                >
                    <span className="absolute inset-0 bg-gradient-to-r from-pink-500 to-orange-400 rounded-custom p-px"></span>
                    <span className="relative bg-darkblue rounded-custom block py-3 px-8 font-bold text-lg">
                        Added to my homescreen
                    </span>
                </button>
            </div>
            </div>

            </>}

            {/* {showWelcomeBack && (
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-transparent">
                    <WelcomeBackScreen onClose={() => setShowWelcomeBack(false)}/>
                </div>
            )} */}
        </>
    );
};

export default Dashboard;
