import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { requestNotificationPermission } from 'src/firebaseConfig';
import { fetchUser } from 'src/services/dashboard/api';
import { updateUserFcmToken } from 'src/services/auth/api';
import { onFcmTokenRefresh } from 'src/firebaseConfig';
interface AuthContextType {
  isAuthenticated: boolean;
  user: any;
  isNewUser: boolean;
  selectedCauseId: string | null;
  donationAmount: number | null;
  appDonationAmount: number | null;
  coinsEarned: number | null;
  login: (user: any) => void;
  logout: () => void;
  signup: (user: any) => void;
  resetNewUser: () => void;
  updateUser: (param: any) => Promise<void>; // Add updateUser function type
  setSelectedCauseId: (id: string | null) => void;
  setDonationAmount: (amount: number) => void;
  setAppDonationAmount: (amount: number) => void;
  setCoinsEarned: (amount: number) => void;
}

interface AuthProviderProps {
  children: ReactNode;
  navigate: (path: string) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, navigate }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [selectedCauseId, setSelectedCauseId] = useState<string | null>(null);
  const [donationAmount, setDonationAmount] = useState<number | null>(null);
  const [appDonationAmount, setAppDonationAmount] = useState<number | null>(null);
  const [coinsEarned, setCoinsEarned] = useState<number | null>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedIsNewUser = localStorage.getItem('isNewUser');
    const storedCauseId = localStorage.getItem('selectedCauseId');
    const storedDonationAmount = localStorage.getItem('donationAmount');
    const storedAppDonationAmount = localStorage.getItem('appDonationAmount');
    const storedCoinsEarned = localStorage.getItem('coinsEarned');

    if (storedUser) {
      setIsAuthenticated(true);
      setUser(JSON.parse(storedUser));
      setIsNewUser(storedIsNewUser === 'true');
      setSelectedCauseId(storedCauseId);
      setDonationAmount(storedDonationAmount ? parseFloat(storedDonationAmount) : null);
      setAppDonationAmount(storedAppDonationAmount ? parseFloat(storedAppDonationAmount) : null);
      setCoinsEarned(storedCoinsEarned ? parseFloat(storedCoinsEarned) : null);
    }
  }, []);

  const login = async (user: any) => {
    try {
      // First store user data and navigate
      localStorage.clear();
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('isNewUser', 'false');
      setIsAuthenticated(true);
      setUser(user);
      setIsNewUser(false);
      
      // Then handle FCM token
      const fcmToken = await requestNotificationPermission();
      if (fcmToken) {
        await updateUserFcmToken({
          userId: user.id,
          fcmToken
        });

        // Set up token refresh listener
        onFcmTokenRefresh(async (newToken) => {
          try {
            await updateUserFcmToken({
              userId: user.id,
              fcmToken: newToken
            });
          } catch (error) {
            console.error('Error updating FCM token:', error);
          }
        });
      }

      navigate('/dashboard');
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  const signup = async (user: any) => {
    try {
      // First store user data and navigate
      localStorage.clear();
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('isNewUser', 'true');
      setIsAuthenticated(true);
      setUser(user);
      setIsNewUser(true);
      
      // Then handle FCM token
      const fcmToken = await requestNotificationPermission();
      if (fcmToken) {
        await updateUserFcmToken({
          userId: user.id,
          fcmToken
        });

        // Set up token refresh listener
        onFcmTokenRefresh(async (newToken) => {
          try {
            await updateUserFcmToken({
              userId: user.id,
              fcmToken: newToken
            });
          } catch (error) {
            console.error('Error updating FCM token:', error);
          }
        });
      }

      navigate('/congratulations');
    } catch (error) {
      console.error('Error during signup:', error);
      throw error;
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setIsNewUser(false);
    setSelectedCauseId(null);
    setDonationAmount(null);
    setAppDonationAmount(null);
    setCoinsEarned(null);
    localStorage.clear();
    navigate('/email');
  };

  const resetNewUser = () => {
    localStorage.setItem('isNewUser', 'false');
    setIsNewUser(false);
  };

  const updateUser = async (param: any) => {
  try {
    const updatedData: any = await fetchUser(param);
    const prevUser = JSON.parse(localStorage.getItem('user') || '{}');
    const newUser = {
      ...prevUser,          
      ...updatedData,  
    };

    setUser(newUser);
    localStorage.setItem('user', JSON.stringify(newUser));

  } catch (error) {
    console.error("Failed to update user:", error);
  }
};


  const handleSetSelectedCauseId = (id: string | null) => {
    setSelectedCauseId(id);
    if (id) {
      localStorage.setItem('selectedCauseId', id);
    } else {
      localStorage.removeItem('selectedCauseId');
    }
  };

  const handleSetDonationAmount = (amount: number) => {
    setDonationAmount(amount);
    localStorage.setItem('donationAmount', amount.toString());
  };

  const handleSetAppDonationAmount = (amount: number) => {
    setAppDonationAmount(amount);
    localStorage.setItem('appDonationAmount', amount.toString());
  };

  const handleSetCoinsEarned = (amount: number) => {
    setCoinsEarned(amount);
    localStorage.setItem('coinsEarned', amount.toString());
  };

  return (
    <AuthContext.Provider 
      value={{ 
        isAuthenticated, 
        user, 
        isNewUser, 
        selectedCauseId, 
        donationAmount, 
        appDonationAmount, 
        coinsEarned, 
        login, 
        logout, 
        signup, 
        resetNewUser, 
        updateUser,  // Expose updateUser to the context
        setSelectedCauseId: handleSetSelectedCauseId,
        setDonationAmount: handleSetDonationAmount,
        setAppDonationAmount: handleSetAppDonationAmount,
        setCoinsEarned: handleSetCoinsEarned 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
