import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import images from 'src/assets/images';
import TravelInfoCard from 'src/components/reward/travelInfoCard';
import RedemptionFeesCard from 'src/components/reward/redemptionFeesCard';
import StepList from 'src/components/reward/stepList';
import TestimonialCard from 'src/components/reward/testimonialCard';
import FundcoinPurchaseCard from 'src/components/reward/FundcoinPurchaseCard';
import { fetchReward, checkRewardAvailed } from 'src/services/reward/api';
import { useAuth } from 'src/contexts/authContext';
import ConfirmRewardPurchase from './confirmRewardPurchase';
import RewardPurchaseVerification from './rewardPurchaseVerification';
import RewardPurchaseComplete from './rewardPurchaseComplete';
import FundcoinRedeemCard from 'src/components/reward/FundcoinRedeemCard';
import MediaGallery from 'src/components/common/mediaGallery';
import { getReward } from 'src/services/reward/api';
import { toast } from 'react-hot-toast';

const RewardDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [reward, setReward] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [fundCoinBalance, setFundCoinBalance] = useState(0);
  const [isRewardPurchased, setIsRewardPurchased] = useState(false);
  const { user } = useAuth();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const handleRedeemReward = async () => {
    try {
      await getReward({ userId: user.id, rewardId: id });
    
      setIsCompleteModalOpen(true);
    } catch (error) {
      toast.error('Failed to redeem reward. Please try again.');
    }
  };

  const handleNext = async () => {
    setIsConfirmModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmPurchase = () => {
    setIsConfirmModalOpen(false);
    setIsVerificationModalOpen(true);
  };

  const handleCloseVerificationModal = () => {
    setIsVerificationModalOpen(false);
  };

  const handleVerificationComplete = () => {
    setIsVerificationModalOpen(false);
    setIsCompleteModalOpen(true);
  };

  const handleCloseCompleteModal = () => {
    setIsCompleteModalOpen(false);
  };

  useEffect(() => {
    const fetchRewardDetails = async () => {
      try {
        setLoading(true);
        setError(null);

        const rewardData: any = await fetchReward({ id: id });
        setReward(rewardData);
        setFundCoinBalance(user.fundcoinBalance || 0);

        const checkRewardResponse: any = await checkRewardAvailed({ rewardId: id, userId: user.id });

        if (checkRewardResponse && checkRewardResponse.isAvailed) {
          setIsRewardPurchased(true);
        } else {
          setIsRewardPurchased(false);
        }
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRewardDetails();
  }, [id, user.fundcoinBalance, user.id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!reward) {
    return <p>No Reward details found</p>;
  }

  const travelItems = reward.rewardDetails ? reward.rewardDetails.split(/\s{2,}/) : [];

  const activationsteps = reward.rewardActivateInstructions || [];
  const redemptionsteps = reward.redemptionInstructions || [];

  const isBalanceSufficient = fundCoinBalance >= reward.fundcoinCost;

  return (
    <>
      <div className="flex flex-col items-center w-full max-w-2xl mx-auto px-4 sm:px-6 mt-4 sm:mt-6 flex-grow overflow-x-hidden scrollbar-hide">
        <button
          onClick={handleBack}
          className="absolute top-6 left-6 z-20 bg-white text-gray-900 p-3 rounded-full shadow-md hover:bg-gray-200 focus:outline-none"
        >
          <img src={images.leftarrow} alt="Back" className="w-3 h-3" />
        </button>

        <div className="flex-grow overflow-y-auto w-full scrollbar-hide">
          <MediaGallery
            mainImage={reward.image1Url}
            additionalImages={[
              reward.image2Url || null,
              reward.image3Url || null,
              ].filter((url) => url)} 
            videoUrl={reward.videoUrl}
            altText={reward.name}
          />

          <div className="w-full">
            <h3
              className="text-sm font-bold mb-2"
              style={{
                background: 'linear-gradient(to bottom, #EC4899, #FB923C)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {reward.CategoryName || 'Category Name'}
            </h3>
            <h2 className="text-3xl font-bold text-gray-900">{reward.name || 'Reward Name'}</h2>
            <p className="text-black font-normal">{reward.description || 'Reward Description'}.</p>

            <div className="mt-10">
              <h2 className="text-lg font-semibold text-black mb-2">Reward Details:</h2>
              <TravelInfoCard items={travelItems} />
            </div>

            <h2 className="text-lg font-bold text-gray-900 mt-8">Additional Fees</h2>
            <RedemptionFeesCard
              redemptionFee={reward.redemptionFee || 0}
              taxesAndFees={reward.taxesAndFee || 0}
            />
            {activationsteps && activationsteps.length > 0 && (
              <>
                <h2 className="text-lg font-bold text-gray-900 mt-8 mb-4">How it Works:</h2>
                <StepList steps={activationsteps} />
              </>
            )}
            <h2 className="text-lg font-bold text-gray-900 mt-8 mb-4">Testimonials:</h2>
            <TestimonialCard
              rating={4}
              text="“Our honeymoon was amazing and relaxing. So happy we were able to go right away after our wedding. Our hotel was amazing and we enjoyed walking along the beach each morning with our coffee.”"
              author="Natalie L."
              location="Littleton, CO"
            />
          </div>
          <div className="mt-12 w-full">
            <div className="w-full h-40 bg-gray-100 rounded-lg"></div>
          </div>
        </div>
      </div>

      {!isRewardPurchased && (
        <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto bg-white">
          <FundcoinPurchaseCard
            coinAmount={reward.fundcoinCost}
            onBuyNow={handleNext}
            disabled={!isBalanceSufficient}
          />
        </div>
      )}

      {isRewardPurchased && (
        <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto bg-white">
          <FundcoinRedeemCard
            coinAmount={fundCoinBalance}
            onRedeemNow={handleRedeemReward}
            disabled={!isBalanceSufficient}
          />
        </div>
      )}

      {isConfirmModalOpen && (
        <ConfirmRewardPurchase
          onClose={handleCloseModal}
          onConfirm={handleConfirmPurchase}
          reward={{
            id: reward.id,
            name: reward.name,
            description: reward.description,
            categoryName: reward.CategoryName,
            cost: reward.fundcoinCost,
            image1Url: reward.image1Url,
          }}
          userFundcoinBalance={fundCoinBalance}
        />
      )}

      {isVerificationModalOpen && (
        <RewardPurchaseVerification
          onClose={handleCloseVerificationModal}
          onComplete={handleVerificationComplete}
        />
      )}

      {isCompleteModalOpen && (
        <RewardPurchaseComplete
          onClose={handleCloseCompleteModal}
          reward={{
            title: reward.name,
            categoryName: reward.CategoryName,
            image1Url: reward.image1Url || images.reward_wallpaper,
            rewardWebsiteUrl: reward.rewardWebsiteUrl,
            redemptionSteps: reward.redemptionInstructions
          }}
        />
      )}
    </>
  );
};

export default RewardDetail;
