import React from 'react';
import images from 'src/assets/images';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'src/components/common/progressBar';
import { useSwipeable } from 'react-swipeable';
const IntroScreenTwo: React.FC = () => {
    const navigate = useNavigate();

  const handleNext = () => {
    navigate('/introduction-three');
  };

  const handleBack = () => {
    navigate('/introduction-one');
  };


  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handleBack,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div {...handlers} className="relative w-full h-full flex flex-col items-center justify-center p-4 bg-darkblue">
        <div className="absolute inset-0 bg-cover bg-center rounded-3xl" style={{ backgroundImage: `url(${images.intro_two})` }}></div>
        <div className="absolute bottom-[120px] z-10 flex flex-col items-center text-center px-6"> {/* Adjusted bottom position */}
          <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Choose a cause you<br /><span className="block">want to support</span>
          </h1>
          <p className="text-s mb-2" style={{ color: '#8197AD' }}>
             There are many worthy causes that desperately need funding and you get<br /><span className="block">to decide where your funds will go.</span>
          </p>
          <ProgressBar step={0} />
            <div className="fixed bottom-0 z-10 w-full max-w-[86vw] sm:max-w-[500px] mx-auto px-4 py-4 shadow-lg">

          <button className="relative py-0.5 px-0.5 rounded-custom text-white bg-transparent border-none mt-8 w-full max-w-md" onClick={handleNext}>
            <span className="absolute inset-0 bg-gradient-to-r from-pink-500 to-orange-400 rounded-custom p-px"></span>
            <span className="relative bg-darkblue rounded-custom block py-3 px-12 font-bold text-lg">Next</span>
          </button>
            </div>
        </div>
      </div>
  );
};

export default IntroScreenTwo;
