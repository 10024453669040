// // export const BASE_URL = 'http://192.168.18.12:3000';
// export const BASE_URL = 'http://192.168.18.52:3000';

// export const BASE_URL = 'http://localhost:3000'
export const BASE_URL = 'https://api.fundrgiving.com'
export const API_URLS = {
  signUp: `${BASE_URL}/api/auth/signup`,
  newToken: `${BASE_URL}/api/auth/token`,
  login: `${BASE_URL}/api/auth/login`,
  checkUser: `${BASE_URL}/api/user/fetch-user`,
  fetchUserProfile: `${BASE_URL}/api/user/profile`,
  fetchPopularRewards: `${BASE_URL}/api/reward/fetch-popular`,
  fetchRewards: `${BASE_URL}/api/reward/fetch-all`,
  fetchReward: `${BASE_URL}/api/reward/fetch`,
  getReward: `${BASE_URL}/api/reward/get`,
  checkRewardAvailed: `${BASE_URL}/api/reward/check-user`,
  fetchUserCauses: `${BASE_URL}/api/cause/fetch-user-causes`,
  fetchPopularCauses: `${BASE_URL}/api/cause/fetch-popular`,
  fetchCauses: `${BASE_URL}/api/cause/fetch-all`,
  fetchCause: `${BASE_URL}/api/cause/fetch`,
  setupPaymentIntent: `${BASE_URL}/api/payment/create-setup-intent`,
  checkStripeCheckoutSession: `${BASE_URL}/api/payment/check-checkout-session`,
  checkStripePaymentMethod: `${BASE_URL}/api/payment/check-payment-methods`,
  cancelCauseSubscription: `${BASE_URL}/api/cause/cancel-subscription`,
  createCauseSubscription: `${BASE_URL}/api/payment/create-subscription`,
  updateCauseSubscription: `${BASE_URL}/api/payment/update_subscription`,
  fetchUser: `${BASE_URL}/api/user/fetch-by-id`,
  fetchCauseSubscription: `${BASE_URL}/api/cause/fetch-subscription`,
  updatePaymentMethod: `${BASE_URL}/api/payment/update-payment-method`,
  fetchUserByReferralCode: `${BASE_URL}/api/user/find-user-by-refer-id`,
  fetchUserReferrals: `${BASE_URL}/api/refer/fetch-user-referrals`,
  uploadProfileImage: `${BASE_URL}/api/user/upload-profile`,
  fetchUsersProfile: `${BASE_URL}/api/user/fetch-all`,
  createCustomerSession: `${BASE_URL}/api/payment/create-customer-session`,
  updateUser: `${BASE_URL}/api/user/update`,
  claimHomescreenReward: `${BASE_URL}/api/reward/homescreen`,
};
