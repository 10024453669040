import React from 'react';
import { useNavigate } from 'react-router-dom';
import images from 'src/assets/images';
import Button from 'src/components/common/button';

const CongratulationsScreen: React.FC = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/select-cause');
  };

  return (
    <>
      <div
        className="relative w-full h-screen flex flex-col items-center justify-start"
        style={{
          backgroundImage: `url(${images.congratsBackground})`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
        }}
      ></div>
      <div className="fixed bottom-0 z-10 w-full max-w-[100vw] sm:max-w-[500px] mx-auto px-4 py-4 bg-white">
        <Button onClick={handleStart}>
          Let's start donating
        </Button>
      </div>
    </>
  );
};

export default CongratulationsScreen;
