import React from 'react';
import images from 'src/assets/images';

interface AccountSettingsProps {
  settings: { name: string; onClick: () => void }[];
}

const AccountSettings: React.FC<AccountSettingsProps> = ({ settings }) => {
  return (
    <div className="mt-2 bg-[#F2F2F3] rounded-xl">
      {settings.map((setting, index) => (
        <div
          key={index}
          onClick={setting.onClick}
          className={`flex justify-between items-center py-4 px-4 cursor-pointer transition-all duration-200 hover:bg-[#E8ECEE] ${
            index !== settings.length - 1 ? 'border-b border-[#E8ECEE]' : ''
          }`} 
        >
          <span className="text-gray-700">{setting.name}</span>
          <img src={images.right_direction_icon} alt="Go to setting" className="w-4 h-4" />
        </div>
      ))}
    </div>
  );
};

export default AccountSettings;
