import React from 'react';
import images from 'src/assets/images';

interface UserCauseCardProps {
  causeWallPaper: string;
  causeLogo: string;
  causeName: string;
  causeMission: string;  
  raisedAmount: number;
  goalAmount: number;
  buyersCount: number;  
  buyersImages: string[];  
  onClick: () => void; // Add onClick prop
}

const UserCauseCard: React.FC<UserCauseCardProps> = ({
  causeWallPaper,
  causeLogo,
  causeName,
  causeMission,
  raisedAmount,
  goalAmount,
  buyersCount,
  buyersImages,
  onClick,
}) => {
  const progress = Math.min((raisedAmount / goalAmount) * 100, 100);
  const height = 6;  
  const radius = height / 2;
  const progressLength = `${progress}%`;

  return (
    <div 
      onClick={onClick} // Use onClick prop here
      className="rounded-xl shadow-lg overflow-hidden w-full bg-[#FBDFDF] cursor-pointer"
    >
      <div className="relative">
        <img src={causeWallPaper} alt={causeName} className="w-full h-40 object-cover" />
        <div className="absolute top-1 left-3 p-1 rounded-full bg-transparent">
          <div className="p-0.5 rounded-full" 
               style={{
                 background: 'linear-gradient(to right, #EC4899, #FB923C)',
               }}>
            <div className="p-[2px] bg-[#FBDFDF] rounded-full">
              <img
                src={causeLogo} 
                alt="Cause Logo"
                className="w-8 h-8 rounded-full object-cover"
              />
            </div>
          </div>
        </div>
        <div className="absolute top-3 right-3 flex items-center bg-[#0A1E32] bg-opacity-80 text-white rounded-full py-1 px-3 space-x-2">
          <button className="rounded-full bg-opacity-0">
            <img 
              src={images.reload_icon} 
              alt="Reload Icon"
              className="w-5 h-5"
            />          
          </button>
          <span className="text-sm">FUNDING</span>          
        </div>
      </div>
      <div className="p-4">
        <h3 className="font-bold text-xl text-gray-900">{causeName}</h3>
        <p className='mb-2'>
          <span className="text-sm text-black">{causeMission}</span>
        </p>      
        <svg width="100%" height={height}>
          <rect
            x="0"
            y="2"
            width="100%"
            height={height - 4}
            rx={radius}
            ry={radius}
            fill="#787B7E" 
          />
          <rect
            x="0"
            y="0"
            width={progressLength}
            height={height}
            rx={radius}
            ry={radius}
            fill="#0A1E32" 
          />
        </svg>
        <p className='mt-2'>
          <strong className="text-black">${raisedAmount.toLocaleString()}</strong>{' '}
          <span className="text-[#787B7E]">raised out of </span>
          <strong className="text-[#787B7E]">${goalAmount.toLocaleString()}</strong>        
        </p>
        <div className="mt-2 flex items-center relative ml-5">
          {buyersImages.slice(0).reverse().map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Buyer ${index + 1}`}
              className="w-10 h-10 rounded-full border-2 border-blue"
              style={{
                marginLeft: `${-3 * 6}px`,
                zIndex: index + 1,
              }}
            />
          ))}
          <div
            className="w-10 h-10 bg-white text-black text-xs rounded-full flex items-center justify-center border-2 border-white"
            style={{
              zIndex: buyersImages.length + 1,
              marginLeft: `${-buyersImages.length * 6}px`,
            }}
          >
            {buyersCount}
          </div>
          <span className="ml-4 text-xs">
            Others are <br />currently donating
          </span>
        </div>       
      </div>
    </div>
  );
};

export default UserCauseCard;
