import { get, post } from "../request";
import { API_URLS } from "src/constants/apiUrls";

export const fetchUserReferrals = async (param: any) => {
  try {
    const data = await post(API_URLS.fetchUserReferrals, param);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchUsersProfile = async () => {
  try {
    const data = await get(API_URLS.fetchUsersProfile);
    return data;
  } catch (error) {
    throw error;
  }
};