import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'src/components/common/button';
import { InputField } from 'src/components/common/inputField';
import images from 'src/assets/images';
import ProgressBar from 'src/components/common/progressBar';
import { sendOtp } from '../functions/sendOtp';
import useRedirectIfNoState from 'src/hooks/redirectHook';
import { toast } from 'react-toastify';
import { RecaptchaVerifier, getAuth } from 'firebase/auth';
import { app } from 'src/firebaseConfig';

declare var grecaptcha: {
  reset: (widgetId?: string) => void;
};

const PhoneNumberPasswordScreen: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { email, name } = location.state as { email: string, name: string } || {};
  const [loading, setLoading] = useState(false);

  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);
  const auth = getAuth(app);

  useRedirectIfNoState('email' || 'name');

  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem('phoneNumber');
    const storedPassword = localStorage.getItem('password');

    if (storedPhoneNumber) setPhoneNumber(storedPhoneNumber);
    if (storedPassword) setPassword(storedPassword);

    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: (response: any) => {
          console.log('reCAPTCHA solved:', response);
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired, resetting...');
          recaptchaVerifierRef.current?.render().then((widgetId: any) => {
            grecaptcha.reset(widgetId);
          });
        },
      });
    }
  }, [auth]);

  const handleChange = (e: string | React.ChangeEvent<HTMLInputElement>) => {
    if (typeof e === 'string') {
      setPhoneNumber(e);
    } else {
      setPassword(e.target.value);
    }
  };

  const handleBack = () => {
    navigate('/name', { state: { email, name } });
  };

  const handleNext = async () => {
    setLoading(true);
    try {
      localStorage.setItem('phoneNumber', phoneNumber);
      localStorage.setItem('password', password);

      await sendOtp(phoneNumber);
      navigate('/sms-verification', { state: { email, name, phoneNumber, password } });
    } catch (error: any) {
      console.error('Error sending OTP:', error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div id="recaptcha-container"></div>
      <div className="relative w-full h-full flex flex-col items-center p-4 bg-white">
        <div className="absolute top-4 left-4 flex justify-between items-center w-full">
          <img
            src={images.leftarrow}
            alt="Back"
            className="w-4 h-4 cursor-pointer mt-3"
            onClick={handleBack}
          />
          </div>
            <ProgressBar step={2} color="#0A1E32" />
            <div className="mt-10 w-full max-w-md text-left">
            <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Thanks! Now let's secure your account.
          </h1>
          <p className="text-1xl mb-4" style={{ color: '#0A1E32' }}>
            Please enter your phone number and create a password you will remember to keep your account safe and secure.
          </p>
          <InputField
            type="phone"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={handleChange}
          />
          <InputField
            type="password"
            placeholder="Password"
            value={password}
            onChange={handleChange}
          />
        </div>
        <div className="fixed bottom-4 w-full max-w-[86vw] sm:max-w-[500px]">
          <Button onClick={handleNext} disabled={!phoneNumber || !password || loading}>
            {loading ? 'Loading...' : 'Next'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PhoneNumberPasswordScreen;
