import React, {useState} from 'react';

interface LogoutButtonProps {
  onLogout: () => void;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ onLogout }) => {
    const [showModal, setShowModal] = useState(false);

    return (
    <div className="mt-4 bg-[#F2F2F3] rounded-xl">
      <div
        onClick={() =>setShowModal(true)}
        className="flex justify-between items-center py-4 px-4 cursor-pointer transition-all duration-200 hover:bg-[#E8ECEE] rounded-lg"
      >
        <span className="text-black font-normal">Logout</span>
      </div>
        {showModal ? (
            <div className="fixed inset-0 flex items-center justify-center bg-gray bg-opacity-50 backdrop-blur-sm">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h2 className="text-lg font-semibold mb-4">
                        Are you sure you want to logout?
                    </h2>

                    {/* Buttons */}
                    <div className="flex justify-end space-x-4">
                        <button
                            className="bg-blue text-white px-4 py-2 rounded"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="bg- text-white px-4 py-2 rounded"
                            style={{ background: 'linear-gradient(63.66deg, #FF2EAB 2.94%, #FFA030 100%)' }}

                            onClick={() => {
                                setShowModal(false);
                                // Add your logout functionality here
                                onLogout();
                                console.log("Logged out");
                            }}
                        >
                            Yes, Logout
                        </button>
                    </div>
                </div>
            </div>
        ) : null}
    </div>
  );
};

export default LogoutButton;
