import React from 'react';

interface Step {
  heading: string;
  description: string;
}

interface StepListProps {
  steps: Step[];
}

const StepList: React.FC<StepListProps> = ({ steps }) => {
  return (
    <div className="space-y-6">
      {steps.map((step, index) => (
        <div key={index} className="flex items-start">
          <div className="flex-shrink-0 w-8 h-8 rounded-full bg-[#3D86FF] flex items-center justify-center text-white">
            {index + 1}
          </div>
          <div className="ml-4">
            <h3 className="text-[#3D86FF] font-bold text-xl">{step.heading}</h3>
            <p className="text-md text-black">{step.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepList;
