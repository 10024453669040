import React from 'react';
import { useNavigate } from 'react-router-dom';
import images from 'src/assets/images';

interface ProfileCardProps {
  firstName: string;
  lastName: string;
  profileImage: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ firstName, lastName, profileImage }) => {
  const navigate = useNavigate();

  const handleNavigateToProfile = () => {
    navigate('/profile');
  };

  return (
    <div 
      className="flex justify-between items-center p-4 bg-[#F2F2F3] rounded-xl cursor-pointer"
      onClick={handleNavigateToProfile} // Navigate on click for the whole card
    >
      <div className="flex items-center">
        <img src={profileImage} alt={`${firstName} ${lastName}`} className="w-16 h-16 object-cover cursor-pointer rounded-full" />
        <div className="ml-4">
          <p className="text-md text-gray-500">Profile</p>
          <h2 className="text-xl font-bold">{`${firstName} ${lastName}`}</h2>
        </div>
      </div>
      <img
        src={images.right_direction_icon}
        alt="Go to Profile"
        className="w-4 h-4"
      />
    </div>
  );
};

export default ProfileCard;
