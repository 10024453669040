import React from 'react';
import images from 'src/assets/images';
import { useAuth } from 'src/contexts/authContext';
import { useNavigate } from 'react-router-dom';

interface TopBarProps {
  profileImage: string | null; // Allow null for when the profile image is not set
}

const TopBar: React.FC<TopBarProps> = ({ profileImage }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate('/settings'); // Navigate to profile page
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="relative w-full h-16 sm:h-20 bg-white flex items-center justify-between px-4 sm:px-6">
      <img src={images.topbar_logo} alt="Logo" className="h-6 sm:h-8" />
      <div className="relative">
        <img
          src={profileImage || images.profile} // Use default image if profileImage is empty
          alt="Profile"
          className="w-10 h-10 sm:w-8 sm:h-8 rounded-full object-cover cursor-pointer"
          onClick={handleProfileClick} // Navigate to profile page on click
        />
      </div>
    </div>
  );
};

export default TopBar;
