import React, { useState, useEffect } from 'react';
import images from 'src/assets/images';
import { checkStripePaymentMethod, createCustomerSession } from 'src/services/payment/api';

interface DonationReceiptCardProps {
  hubDonation: number;
  fundrDonation: number;
  transactionFee: number;
  causeWallpaper: string;
  userId: string;
  causeName: string;
  stripeCustomerId: string;
}

const DonationReceiptCard: React.FC<DonationReceiptCardProps> = ({
  hubDonation,
  fundrDonation,
  transactionFee,
  causeWallpaper,
  causeName,
  userId,
  stripeCustomerId,
}) => {
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const totalDonation = hubDonation + fundrDonation + transactionFee;

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      try {
        const result: any = await checkStripePaymentMethod({ userId });

        if (result && result.hasPaymentMethods) {
          setPaymentMethod(result.defaultPaymentMethod || result.paymentMethods[0]);
        }
      } catch (error) {
        console.error('Failed to fetch payment methods:', error);
      }
    };

    fetchPaymentMethod();
  }, [userId]);

  const handleUpdateCardClick = async () => {
    try {
      const response: any = await createCustomerSession({
        customerId: stripeCustomerId, // Pass the user ID or customer ID as required by your function
        returnUrl: window.location.href, // URL to redirect back to after updating
      });
      window.location.href = response.url; // Redirect to Stripe's Customer Portal URL
    } catch (error) {
      console.error('Failed to create customer portal session:', error);
    }
  };

  const renderPaymentIcon = () => {
    if (!paymentMethod) return null;

    switch (paymentMethod.card.brand) {
      case 'visa':
        return <img src={images.visa_card_icon} alt="Visa" className="w-8 h-8 sm:w-10 sm:h-10" />;
      case 'mastercard':
        return <img src={images.master_card_icon} alt="MasterCard" className="w-8 h-8 sm:w-10 sm:h-10" />;
      case 'apple_pay':
        return <img src={images.apple_pay_icon} alt="Apple Pay" className="w-8 h-8 sm:w-10 sm:h-10" />;
      default:
        return <img src={images.paypal_icon} alt="Card" className="w-8 h-8 sm:w-10 sm:h-10" />;
    }
  };

  return (
    <div className="bg-white p-4 sm:p-6 rounded-2xl shadow-md max-w-full mx-auto border border-[#F0F2F5]">
      <div className="flex items-center justify-center mt-2 sm:mt-8 mb-6 sm:mb-8">
        <div className="flex items-center space-x-4">
          <img src={causeWallpaper} alt="Cause Wallpaper" className="w-16 h-16 sm:w-20 sm:h-20 rounded-full" />
          {fundrDonation > 0 && (
            <img src={images.fund_coin_icon} alt="Fundr" className="w-16 h-16 sm:w-20 sm:h-20 rounded-full" />
          )}
        </div>
      </div>

      <hr className="custom-dash-line py-4" />

      <div className="mb-4">
        <div className="flex justify-between text-xs sm:text-sm text-[#8197AD] mb-4 border-b-2 border-[#E5EAEF] pb-2">
          <span>{causeName} Donation</span>
          <span>${hubDonation.toFixed(2)}</span>
        </div>
        <div className="flex justify-between text-xs sm:text-sm text-[#8197AD] mb-4 border-b-2 border-[#E5EAEF] pb-2">
          <span>Fundr Donation</span>
          <span>${fundrDonation.toFixed(2)}</span>
        </div>
        <div className="flex justify-between text-xs sm:text-sm text-[#8197AD] mb-4 border-b-2 border-[#E5EAEF] pb-2">
          <span>Transaction Fee</span>
          <span>${transactionFee.toFixed(2)}</span>
        </div>
      </div>

      <div className="flex justify-between font-bold text-base sm:text-lg text-black mb-6 pb-2">
        <span>Total Donation</span>
        <span>${totalDonation.toFixed(2)}</span>
      </div>

      {paymentMethod ? (
        <div
          className="bg-[#F2F2F3] py-2 sm:py-3 rounded-xl border border-[#E5EAEF] flex items-center cursor-pointer"
          onClick={handleUpdateCardClick}
        >
          <div className="ml-4">
            {renderPaymentIcon()}
          </div>
          <div className="flex flex-col mx-4">
            <div className="text-xs sm:text-sm text-[#8298AE] font-normal">
              {paymentMethod.card.brand.charAt(0).toUpperCase() + paymentMethod.card.brand.slice(1)} <br />
              <span>Card ending in **{paymentMethod.card.last4}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-[#F2F2F3] text-center text-xs sm:text-sm text-[#8298AE] py-4 sm:py-6 rounded-xl border border-[#E5EAEF]">
          No Payment Method Added
        </div>
      )}
    </div>
  );
};

export default DonationReceiptCard;
