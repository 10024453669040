import React, { useEffect, useState } from 'react';
import images from 'src/assets/images';
import { getReward } from 'src/services/reward/api'; 
import { useAuth } from 'src/contexts/authContext'; 
import { toast } from 'react-toastify';
import { formatFundcoinAmount } from 'src/utils/formatters';

interface ConfirmRewardPurchaseProps {
  onClose: () => void;
  onConfirm: () => void;
  reward: {
    id: string;
    name: string;
    description: string;
    categoryName: string;
    cost: number;
    image1Url: string;
  };
  userFundcoinBalance: number; 
}

const ConfirmRewardPurchase: React.FC<ConfirmRewardPurchaseProps> = ({
  onClose,
  onConfirm,
  reward,
  userFundcoinBalance,
}) => {
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user, updateUser } = useAuth(); 

  useEffect(() => {
    if (userFundcoinBalance < reward.cost) {
      setIsConfirmDisabled(true);
    } else {
      setIsConfirmDisabled(false);
    }
  }, [userFundcoinBalance, reward.cost]);

  const handleConfirmPurchase = async () => {
    try {
      setIsLoading(true); 
      const response: any = await getReward({ userId: user.id, rewardId: reward.id });

      if (response) {
        await updateUser({ userId: user.id }); 
        onConfirm();
      } else {
        toast.error('Failed to complete the reward purchase.');
      }
    } catch (error) {
      console.error('Error during purchase:', error);
      toast.error('An error occurred while processing the reward purchase.');
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div className="w-auto">
      <div
        className="fixed inset-0 z-10 bg-[#0A1E32] bg-opacity-70 max-w-[548px] mx-auto"
        style={{ backdropFilter: 'blur(3px)' }}
      />
      <div className="fixed inset-x-0 bottom-0 bg-[#0A1E32] bg-opacity-90 rounded-t-3xl z-40 h-[calc(70%-2rem)] max-w-[548px] w-full mx-auto p-4 sm:p-6">
        <button className="absolute top-5 right-6 z-50 bg-transparent" onClick={onClose}>
          <img src={images.close_icon} alt="Close" className="w-4 h-4" />
        </button>
        <div className="text-[#FFFFFF] text-opacity-50 text-lg">PURCHASE CONFIRMATION</div>
        <hr className="custom-dash-line" />
        <div className="px-2 flex items-center rounded-2xl mt-2 text-white">
          <div className="w-24 h-24 rounded-lg overflow-hidden">
            <img src={reward.image1Url || images.reward_wallpaper} alt="Reward" className="w-full h-full object-cover" />
          </div>
          <div className="ml-4">
            <h3
              className="text-sm font-bold mb-1"
              style={{
                background: 'linear-gradient(to bottom, #EC4899, #FB923C)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {reward.categoryName || 'Category Name'}
            </h3>
            <h2 className="text-xl font-bold text-white">{reward.name || 'Reward Name'}</h2>
          </div>
        </div>
        <div className="mt-2 px-2">
          <p className="text-white font-normal mt-1 text-xs max-h-12 overflow-hidden">
            {reward.description || 'Reward Description'}
          </p>
        </div>
        <div className="text-sm text-[#3D86FF] underline mt-2">Additional Taxes & Fees Required</div>
        <hr className="custom-dash-line" />
        <div className="flex justify-between items-center mt-6 px-4">
          <p className="text-lg text-[#FFFFFF]">Total Cost</p>
          <div className="flex flex-col items-center">
            <div className="flex items-center space-x-2">
              <img src={images.fund_coin_icon} alt="Fundcoin Icon" className="w-7 h-7" />
              <p className="text-2xl text-white font-bold">{formatFundcoinAmount(reward.cost || 0)}</p>
            </div>
            <p className="ml-6 text-xs text-[#FFFFFF] text-opacity-60">FUNDCOIN</p>
          </div>
        </div>
        <div className="mt-3 ">
          <button
            className={`w-full bg-gradient-to-r from-pink-500 to-orange-400 text-white py-3 rounded-xl font-semibold text-lg ${
              isConfirmDisabled || isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleConfirmPurchase}
            disabled={isConfirmDisabled || isLoading}
          >
            {isLoading ? 'Processing...' : isConfirmDisabled ? 'Insufficient Balance' : 'CONFIRM PURCHASE'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRewardPurchase;
