import React from 'react';
import images from 'src/assets/images';

interface RecommendedCauseCardProps {
  causeWallPaper: string;
  causeLogo: string;
  causeName: string;
  causeMission: string;
  buyersCount: number;
  onClick: () => void;
}

const RecommendedCauseCard: React.FC<RecommendedCauseCardProps> = ({
  causeWallPaper,
  causeLogo,
  causeName,
  causeMission,
  buyersCount,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="relative rounded-xl shadow-lg bg-white overflow-hidden cursor-pointer border-4 border-[#3D86FF] w-full h-80 sm:h-96 md:h-[400px]" // Responsive height
    >
      <div className="relative h-full">
        <img
          src={causeWallPaper}
          alt={causeName}
          className="w-full h-2/3 object-cover"
        />
        <div className="absolute top-12 left-2">
          <div className="bg-white rounded-full">
            <img
              src={images.success_icon_blue}
              alt="Selected"
              className="w-8 h-8"
            />
          </div>
        </div>
        <div className="absolute bottom-36 right-2">
          <div className="p-1 bg-white rounded-full shadow-md">
            <img
              src={causeLogo}
              alt={`${causeName} logo`}
              className="w-12 h-12 rounded-full"
            />
          </div>
        </div>
        <div className="absolute bottom-0 w-full bg-white p-3">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-bold text-black">{causeName}</h3>
          </div>
          <p className="text-md text-black">{causeMission}</p>
          <div className="flex items-center justify-between mt-2 space-x-2">
            <div className="flex items-center">
              <div
                className="flex items-center justify-center text-xs font-medium text-white bg-black rounded-full"
                style={{
                  width: '40px',
                  height: '40px',
                }}
              >
                {buyersCount}
              </div>
              <span className="ml-1 text-xs text-gray-500">
                Others are<br/>currently donating
              </span>
            </div>
            <button
              className=" bg-[#3D86FF] text-white text-sm font-bold py-3 px-6 rounded-xl whitespace-nowrap"
            >
              Donate now
            </button>
          </div>
        </div>
      </div>
      <div className="absolute top-0 left-0 right-0 text-center bg-[#3D86FF] text-white py-3 text-sm">
        RECOMMENDED CHOICE
      </div>
    </div>
  );
};

export default RecommendedCauseCard;
