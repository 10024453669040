import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DonationSection from 'src/components/profile/donationSection';
import images from 'src/assets/images';
import { useAuth } from 'src/contexts/authContext';
import { fetchUserProfile } from 'src/services/dashboard/api';

type CauseSubscriptionWithCause = {
  causeId: number;
  amount: string;
  status: string;
  fundcoinsEarned: number;
  "Cause.id": number;
  "Cause.name": string;
  "Cause.goalAmount": number;
  "Cause.currentAmount": number;
  "Cause.causeProfileUrl": string;
  "Cause.image1Url": string;
  "Cause.mission": string;
  "Cause.description": string;
};

const DonationHistory: React.FC = () => {
  const { user } = useAuth();
//   const [causes, setCauses] = useState([]);
  const [causesDonation, setCausesDonation] = useState([]);
//   const [totalDonations, setTotalDonations] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    handleUserDetailFetch();
  }, []);

  const handleUserDetailFetch = async () => {
    const userProfileData: any = await fetchUserProfile({ userId: user.id });
    // setCauses(userProfileData.causes || []);
    // setTotalDonations(userProfileData.totalDonations || 0);
    setCausesDonation(userProfileData.userAllCausesSubscriptions || []);
  };

  const handleBack = () => {
    navigate('/settings'); // Adjust to the correct route
  };

  return (
    <div className="relative h-screen overflow-hidden">
      <div className="absolute top-6 flex justify-between items-center w-full px-4">
        <img
          src={images.leftarrow}
          alt="Back"
          className="w-4 h-4 cursor-pointer mt-3"
          onClick={handleBack}
        />
        <div className="flex-grow flex justify-center">
          <h1 className="text-lg font-bold mt-2">Donations History</h1>
        </div>
      </div>

      {/* Scrollable content container */}
      <div className="pt-20 overflow-y-auto h-full pb-20 scrollbar-hide">
        {causesDonation?.length > 0 ? (
          causesDonation.map((cause: CauseSubscriptionWithCause) => (
            <DonationSection
              key={cause.causeId}
              donationAmount={cause.amount}
              fundcoinEarned={cause.fundcoinsEarned}
              causeLogo={cause['Cause.causeProfileUrl']}
              causeName={cause['Cause.name']}
              causeId={cause.causeId}
              subscriptionStatus={cause.status}
            />
          ))
        ) : (
          <p className="text-center text-[#787B7E] mt-4">No donations found</p>
        )}
      </div>
    </div>
  );
};

export default DonationHistory;
