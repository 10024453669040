import React from 'react';
import images from "../assets/images";
import {useNavigate} from "react-router-dom";

const TermsAndConditions = () => {
    const navigate = useNavigate()
    return (
        <div className="bg-gray-100 text-gray-800 min-h-screen p-6 max-h-[100%] overflow-y-auto ">

            <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
                <button
                    onClick={() => navigate(-1)}
                    className="absolute top-6 left-6 z-20 bg-white bg-opacity-60 text-gray-900 p-3 rounded-full shadow-md hover:bg-gray-200 focus:outline-none"
                >
                    <img src={images.leftarrow} alt="Back" className="w-3 h-3"/>
                </button>

                <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>

                <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
                <p className="mb-4">
                    Welcome to Fundr! These terms ("Terms") govern your use of our mobile application ("App") designed
                    to make charitable giving more rewarding.
                </p>

                <h2 className="text-xl font-semibold mb-2">2. Acceptance of Terms</h2>
                <p className="mb-4">
                    By downloading, installing, or using the App, you agree to these Terms. If you don't agree, don't
                    use the App.
                </p>

                <h2 className="text-xl font-semibold mb-2">3. Your Privacy</h2>
                <p className="mb-4">
                    Fundr respects your privacy. We handle your personal data according to our Privacy Policy
                    (<a href="/privacy" className="text-blue-600 underline">link to Privacy Policy</a>).
                    This policy explains how we collect, use, and protect your information.
                </p>

                <h2 className="text-xl font-semibold mb-2">4. Using Fundr</h2>
                <p className="mb-4">
                    <strong>Personal Use:</strong> The App is intended for your personal, non-commercial use. You can't
                    use it for business purposes or to resell its features.
                </p>
                <p className="mb-4">
                    <strong>Account Security:</strong> You are responsible for keeping your account credentials
                    confidential and secure. Avoid sharing your password with anyone.
                </p>
                <p className="mb-4">
                    <strong>Prohibited Activities:</strong> You agree not to:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>Use the App for any unlawful purpose, such as fraud or harassment.</li>
                    <li>Impersonate another person or create a false identity.</li>
                    <li>Transfer your account to someone else.</li>
                    <li>Interfere with the App's operation or attempt to hack it.</li>
                </ul>

                <h2 className="text-xl font-semibold mb-2">5. Earning Rewards</h2>
                <p className="mb-4">
                    <strong>Donation Rewards:</strong> When you donate to approved organizations through Fundr, you'll
                    earn rewards. These rewards can be used for various purposes, such as discounts, experiences, or
                    charitable contributions.
                </p>
                <p className="mb-4">
                    <strong>Reward Structure:</strong> The specific rewards offered and how to redeem them may vary.
                    Refer to the App's FAQ section for the most current information.
                </p>
                <p className="mb-4">
                    <strong>Third-Party Fulfillment:</strong> We are not responsible for fulfilling the rewards. Rewards
                    are provided by third-party businesses and are subject to their terms and conditions. We are merely
                    a distributor of these rewards.
                </p>

                <h2 className="text-xl font-semibold mb-2">6. Termination</h2>
                <p className="mb-4">
                    We may terminate your access to the App if you violate these Terms or engage in harmful activities.
                </p>

                <h2 className="text-xl font-semibold mb-2">7. Feedback</h2>
                <p className="mb-4">
                    Your feedback is valuable to us! We encourage you to share your thoughts and suggestions on how we
                    can improve the App. You grant us the right to use your feedback for any purpose, such as developing
                    new features or improving existing ones.
                </p>

                <h2 className="text-xl font-semibold mb-2">8. Disclaimer</h2>
                <p className="mb-4">
                    The App is provided "as is" and "as available" without any warranties. We don't guarantee that the
                    App will be error-free or always accessible.
                </p>

                <h2 className="text-xl font-semibold mb-2">9. Limitation of Liability</h2>
                <p className="mb-4">
                    We are not liable for any damages arising from your use of the App or any issues beyond our control.
                    This includes, but is not limited to, technical difficulties, lost data, or any indirect,
                    incidental, or consequential damages.
                </p>

                <h2 className="text-xl font-semibold mb-2">10. Third-Party Rewards</h2>
                <p className="mb-4">
                    <strong>No Liability:</strong> We are not responsible for the quality, availability, or fulfillment
                    of rewards provided by third-party businesses.
                </p>
                <p className="mb-4">
                    <strong>Third-Party Terms:</strong> Your use of rewards is subject to the terms and conditions of
                    the third-party provider.
                </p>

                <h2 className="text-xl font-semibold mb-2">11. Changes to Terms</h2>
                <p className="mb-4">
                    We may update these Terms from time to time. We'll notify you of significant changes and provide you
                    with an updated version.
                </p>

                <h2 className="text-xl font-semibold mb-2">12. Governing Law</h2>
                <p className="mb-4">
                    These Terms are governed by the laws of [USA]. Any disputes arising under these Terms will be
                    resolved in the courts of [USA].
                </p>

                <h2 className="text-xl font-semibold mb-2">13. Contact Us</h2>
                <p className="mb-4">
                    If you have any questions or need assistance, please contact us through the App or visit our
                    website.
                </p>

                <h2 className="text-xl font-semibold mb-2">14. Entire Agreement</h2>
                <p className="mb-4">
                    These Terms constitute the entire agreement between you and Fundr and supersede any prior or
                    contemporaneous communications or proposals.
                </p>

                <h2 className="text-xl font-semibold mb-2">Additional Explanations</h2>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>Privacy Policy:</strong> Our Privacy Policy outlines how we collect, use, and protect
                        your personal information. It's important to review it for a comprehensive understanding of our
                        privacy practices.
                    </li>
                    <li><strong>Account Security:</strong> Protecting your account information is crucial. Use strong
                        passwords, avoid sharing your login details, and be cautious of phishing attempts.
                    </li>
                    <li><strong>Reward Details:</strong> The specific rewards offered and how to redeem them may vary.
                        Refer to the App's FAQ section for the most current information.
                    </li>
                    <li><strong>Feedback:</strong> Your feedback helps us make Fundr better for everyone. Feel free to
                        share your suggestions, ideas, or report any issues you encounter.
                    </li>
                    <li><strong>Disclaimer:</strong> While we strive to provide a reliable and user-friendly experience,
                        we can't guarantee that the App will be flawless or always available.
                    </li>
                    <li><strong>Governing Law:</strong> The laws of your country will apply to any legal disputes
                        arising from your use of the App.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default TermsAndConditions;
