import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'src/components/common/progressBar';
import Button from 'src/components/common/button';
import { InputField } from 'src/components/common/inputField';
import images from 'src/assets/images';
import { CheckUser } from 'src/services/auth/api';
import { toast } from 'react-toastify';
import validator from 'validator';

const EmailInputScreen: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true); // Track email validity
  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleNext = async () => {
    if (!isEmailValid) {
      toast.error('Please enter a valid email address.');
      return;
    }

    setLoading(true);
    try {
      const data = { email };
      const userExists = await CheckUser(data);
      localStorage.setItem('email', email);

      if (userExists) {
        navigate('/password', { state: { email } });
      } else {
        navigate('/name', { state: { email } });
      }
    } catch (error: any) {
      console.error('Error checking user existence:', error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/introduction-four');
  };

  const handleChange = (e: any) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    validateEmail(inputValue); // Call validation whenever the input changes
  };

  const validateEmail = (email: string) => {
    const isValidEmail = validator.isEmail(email);
    setIsEmailValid(isValidEmail);
  };

  return (
    <div className="relative w-full h-full flex flex-col items-center p-4 bg-white">
      <div className="absolute top-4 left-4 flex justify-between items-center w-full">
        <img
          src={images.leftarrow}
          alt="Back"
          className="w-4 h-4 cursor-pointer mt-3"
          onClick={handleBack}
        />
      </div>
      <ProgressBar step={0} color="#0A1E32" />

      <div className="mt-10 w-full max-w-md text-left">
        <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
          Let’s get started!
        </h1>
        <p className="text-lg mb-4 text-gray-800">
          To join or sign in, please enter your email below.
        </p>
        <InputField
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleChange}
        />
      </div>

      <div className="fixed bottom-4 w-full max-w-[86vw] sm:max-w-[500px]">
        <Button onClick={handleNext} disabled={!email || loading || !isEmailValid}>
          {loading ? 'Loading...' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

export default EmailInputScreen;
