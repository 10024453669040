import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressBar from 'src/components/common/progressBar';
import images from 'src/assets/images';
import { OTPInput } from 'src/components/common/inputField';
import useRedirectIfNoState from 'src/hooks/redirectHook';
import { sendOtp } from '../functions/sendOtp';
import { toast } from 'react-toastify';
import { RecaptchaVerifier, getAuth } from 'firebase/auth';  // Ensure you import RecaptchaVerifier and getAuth
import { app } from 'src/firebaseConfig'; // Import your Firebase app

declare var grecaptcha: {
  reset: (widgetId?: string) => void;
};

const SMSVerificationScreen: React.FC = () => {
  const [otp, setOtp] = useState('');
  const [resending, setResending] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { email, name, phoneNumber, password } = location.state as { email: string, name: string, phoneNumber: string, password: string } || {};
  
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);
  const auth = getAuth(app); // Get the Firebase Auth instance

  useRedirectIfNoState('email' || 'name' || 'phoneNumber' || 'password');

  useEffect(() => {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: (response: any) => {
          console.log('reCAPTCHA solved:', response);
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired, resetting...');
          recaptchaVerifierRef.current?.render().then((widgetId: any) => {
            grecaptcha.reset(widgetId);
          });
        },
      });
    }
  }, [auth]);

  const handleBack = () => {
    navigate('/phone-password', { state: { email, name, phoneNumber, password } }); 
  };

  const handleResendCode = async () => {
    setResending(true);
    try {
      await sendOtp(phoneNumber); 
      toast.success('OTP resent successfully');
    } catch (error) {
      console.error('Error resending OTP:', error);
      toast.error('Failed to resend OTP. Please try again.');
    } finally {
      setResending(false);
    }
  };

  const handleOtpComplete = () => {
    navigate('/loading-verification', { state: { email, name, phoneNumber, password, otp } });
  };

  return (
    <>
      <div id="recaptcha-container"></div>
      <div className="relative w-full h-full flex flex-col items-center p-4 bg-white">
        <div className="absolute top-4 left-4 flex justify-between items-center w-full">
          <img
            src={images.leftarrow}
            alt="Back"
            className="w-4 h-4 cursor-pointer mt-3"
            onClick={handleBack}
          />
          </div>
            <ProgressBar step={1} color="#0A1E32" />
            <div className="mt-10 w-full max-w-md text-left">
            <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Great, let's verify it <br/> went to the right place. 
          </h1>
          <p className="text-1xl mb-7" style={{ color: '#0A1E32' }}>
            Check your SMS inbox for the <br/> verification code we just sent you.
          </p>
          <OTPInput length={6} value={otp} onChange={setOtp} onComplete={handleOtpComplete} />
        </div>
        <div className="absolute bottom-20 w-10/12 ml-2 text-center">
          <p className="text-sm" style={{ color: '#3D86FF' }}>
            Didn't receive a code? <span className="text-sm font-semibold underline cursor-pointer" onClick={handleResendCode} >
              {resending ? 'Resending...' : 'Resend Code'}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default SMSVerificationScreen;
