import React, { useState, useEffect } from 'react';
import images from 'src/assets/images';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'src/components/common/progressBar';
import DonationOptionCard from 'src/components/chooseDonation/donationOptionCard';
import CustomDonation from './customDonation';
import AppDonation from './appDonation';
import { useAuth } from 'src/contexts/authContext';
import Button from 'src/components/common/button'; // Assuming you have a Button component

const ChooseDonation: React.FC = () => {
  const navigate = useNavigate();
  const { setDonationAmount, donationAmount, isNewUser, selectedCauseId } = useAuth();
  const proportionalAmount: number = parseFloat(process.env.REACT_APP_CAUSE_COIN_PROPORTIONAL_AMOUNT || "0");

  const [showCustomDonation, setShowCustomDonation] = useState(false);
  const [showAppDonation, setShowAppDonation] = useState(false);
  const [isInteractionDisabled, setIsInteractionDisabled] = useState(false);

  useEffect(() => {
    if (!donationAmount) {
      setDonationAmount(10);
    }
  }, [donationAmount, setDonationAmount]);

  const handleBack = () => {
    if (isNewUser) {
      navigate('/select-cause');
    } else {
      navigate(`/cause-detail/${selectedCauseId}`);
    }
  };

  const handleSelectCustomAmount = () => {
    setShowCustomDonation(true);
  };

  const handleCloseCustomDonation = () => {
    setShowCustomDonation(false);
  };

  const handleDonationComplete = (amount: number) => {
    setDonationAmount(amount);
    setShowCustomDonation(false);
  };

  const handleMakeDonation = () => {
    setIsInteractionDisabled(true);
    setTimeout(() => {
      setShowAppDonation(true);
      setIsInteractionDisabled(false);
    }, 2000);
  };

  const donationOptions = [
    { amount: 5, label: "That's like getting 1 coffee", coins: 5 * proportionalAmount },
    { amount: 10, label: "That's like getting 2 coffees", coins: 10 * proportionalAmount },
    { amount: 20, label: "That's like getting 4 coffees", coins: 20 * proportionalAmount },
    { amount: 50, label: "That's like getting 10 coffees", coins: 50 * proportionalAmount },
  ];

  return (
    <>
      <div className="p-4 relative flex flex-col h-full">
        <div className="absolute top-4 flex justify-between items-center w-full">
          <img
            src={images.leftarrow}
            alt="Back"
            className="w-4 h-4 cursor-pointer mt-3"
            onClick={handleBack}
          />
        </div>

        <ProgressBar step={1} color="#0A1E32" />

        <div className="mt-4 sm:mt-16 text-left">
          <h1 className="text-2xl sm:text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4 custom-heading">
            Awesome! Now pick a <br /> donation amount
          </h1>
          <p className="text-sm sm:text-md mb-4 text-[#1E1E1E] custom-paragraph">
            Decide how much you want to give each month. For every $1 you donate, we will give you {proportionalAmount} fundcoins.
          </p>
        </div>

        <div className="flex-grow overflow-y-auto space-y-2 pb-24 scrollbar-hide">
          {donationOptions.map((option, index) => (
            <DonationOptionCard
              key={index}
              amount={option.amount}
              label={option.label}
              coins={option.coins}
              checked={donationAmount === option.amount} // Preselect the default amount
              onCheckChange={() => handleDonationComplete(option.amount)}
            />
          ))}

          <div className="mt-4 space-y-4">
            <div className="text-center mt-2">
              <p className="text-sm sm:text-base text-[#8197AD] custom-paragraph">OR</p>
              <button className="text-[#3D86FF] mt-2 text-sm sm:text-base custom-button" onClick={handleSelectCustomAmount}>
                Set a custom amount
              </button>
            </div>
          </div>
        </div>

        {showCustomDonation && (
          <CustomDonation onClose={handleCloseCustomDonation} onComplete={handleDonationComplete} />
        )}

        {showAppDonation && (
          <AppDonation onClose={() => setShowAppDonation(false)} />
        )}
        {isInteractionDisabled && (
          <div className="fixed inset-0 bg-[#0A1E32] bg-opacity-50 z-50"></div>
        )}
      </div>

      {/* Sticky "Make a Donation" Button */}
      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto px-4 py-4 bg-white shadow-lg">
        <div className="flex justify-center">
          <Button onClick={handleMakeDonation} disabled={!donationAmount || isInteractionDisabled}>
            Make a Donation
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChooseDonation;
