import React from 'react';
import images from 'src/assets/images'; 

interface ProfileInfoProps {
  profileImage: string;
  firstName: string;
  lastName: string;
  joinDate: string;
  onUploadClick: () => void; 
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({
  profileImage,
  firstName,
  lastName,
  joinDate,
  onUploadClick,
}) => {
  return (
    <div className="relative flex flex-col items-center mt-12">
      <div className="relative w-24 h-24">
        <img
          src={profileImage}
          alt={`${firstName} ${lastName}`}
          className="w-24 h-24 rounded-full object-cover"
        />
        <button
          onClick={onUploadClick}
          className="absolute bottom-0 right-0 bg-[#F2F2F3] p-1 rounded-full shadow-md"
        >
          <img
            src={images.camera_icon} 
            alt="Upload Profile"
            className="w-6 h-6"
          />
        </button>
      </div>
      <h2 className="text-lg font-bold mt-2">{`${firstName} ${lastName}`}</h2>
      <p className="text-sm text-gray-500">{`Fundr since ${joinDate}`}</p>
    </div>
  );
};

export default ProfileInfo;
