
import logo from './images/logo.svg'
import leftarrow from './icons/arrow-left.svg'
import splashBackground from './images/splash-screen-background.svg'
import intro_one from './images/intro-screen-step-1.png'
import intro_two from './images/intro-screen-step-2.svg'
import intro_three from './images/intro-screen-step-3.svg'
import intro_four from './images/intro-screen-step-4.png'
import hide from './icons/Hide.svg'
import show from './icons/Show.svg'
import congratsBackground from './images/congratulation-screen-background.svg'
import topbar_logo from './images/logo-topbar.svg'
import profile from './icons/profile.svg'
import fund_coin_card from './images/user-balance.svg'
import user_referal from './images/user-referral.svg'
import cause from './images/cause-card.svg'
import reward from './images/reward-card.svg'
import popular from './images/popular-card.svg'
import fund_coin_icon from './icons/fund-coin.svg'
import home_icon from './icons/home_icon.svg'
import reward_icon from './icons/reward_icon.svg'
import search_icon from './icons/search_icon.svg'
import wallet_icon from './icons/wallet_icon.svg'
import refer_icon from './icons/refer_icon.svg'
import cause_wallpaper from './images/cause_wallpaper.png'
import cause_logo from './images/cause_logo.png'
import reload_icon from './icons/reload.svg'
import reward_wallpaper from './images/reward_wallpaper.png'
import popular_reward_wallpaper from './images/popular_reward_wallpaper.png'
import welcome_screen_icon from './icons/welcome_screen_icon.svg'
import search_icon_2 from './icons/search_icon_cause_page.svg'
import message_icon from './icons/message-icon.svg'
import fb_messenger_icon from './icons/fb-messenger-icon.svg'
import instagram_icon from './icons/instagram-icon.svg'
import more_icon from './icons/more-icon.svg'
import success_icon from './icons/success_icon.svg'
import success_icon_blue from './icons/success-icon-blue.svg'
import search_icon_3 from './icons/search_icon_3.svg'
import close_icon from './icons/close_icon.svg'
import payment_success_icon from './icons/payment-success-icon.svg'
import plus_icon from './icons/plus_icon.svg'
import success_icon_2 from './icons/success_icon_2.svg'
import apple_pay_icon from './icons/apple-pay-icon.svg'
import master_card_icon from './icons/master_card_icon.svg'
import visa_card_icon from './icons/visa-card-icon.svg'
import paypal_icon from './icons/paypal-icon.svg'
import right_direction_icon from './icons/right_direction_icon.svg'
import right_arrow_icon from './icons/right_arrow_icon.svg'
import camera_icon from './icons/camera_icon.svg'
import fundr_logo from './images/fundr_logo.png'
import install_icon from './images/install_icon.png'
import help from './images/helpIcon.png'
import closeIcon from './images/closeIcon.png'
import email_icon from './icons/email_icon.svg'
import back_to_app from './icons/back_to_app.svg'
import share_link_card_Background from './images/shareLink_Card_Background.png'
const images = {
    logo,
    leftarrow,
    splashBackground,
    intro_one,
    intro_two,
    intro_three,
    intro_four,
    hide,
    show,
    congratsBackground,
    topbar_logo,
    profile,
    fund_coin_card,
    user_referal,
    cause,
    reward,
    popular,
    fund_coin_icon,
    home_icon,
    reward_icon,
    search_icon,
    wallet_icon,
    refer_icon,
    cause_wallpaper,
    cause_logo,
    reload_icon,
    reward_wallpaper,
    popular_reward_wallpaper,
    welcome_screen_icon,
    search_icon_2,
    message_icon,
    fb_messenger_icon,
    instagram_icon,
    more_icon,
    success_icon,
    success_icon_blue,
    search_icon_3,
    close_icon,
    payment_success_icon,
    plus_icon,
    success_icon_2,
    apple_pay_icon,
    master_card_icon,
    visa_card_icon,
    paypal_icon,
    right_arrow_icon,
    right_direction_icon,
    camera_icon,
    fundr_logo,
    install_icon,
    help,
    closeIcon,
    email_icon,
    back_to_app,
    share_link_card_Background
}
export default images;
