import React from 'react';
import images from 'src/assets/images';
import { useNavigate } from "react-router-dom";
import { formatFundcoinAmount } from 'src/utils/formatters';

interface DonationProps {
  donationAmount: string;
  fundcoinEarned: number;
  causeLogo: string;
  causeName: string;
  causeId: number;
  subscriptionStatus: string;
}

const DonationSection: React.FC<DonationProps> = ({
  donationAmount,
  fundcoinEarned,
  causeLogo,
  causeName,
  causeId,
  subscriptionStatus,
}) => {
  const navigate = useNavigate();
  
  const handleNavigate = (id: number) => {
    navigate(`/cause-detail/${id}?myCause=true`);
  };

  return (
    <div className="relative bg-[#F2F2F3] rounded-xl p-4 mt-6 w-11/12 mx-auto">
      <p className="absolute top-2 left-4 text-sm font-semibold text-[#8197AD] uppercase">My Donation</p>
      
      {subscriptionStatus === "active" && (
        <button 
          onClick={() => handleNavigate(causeId)} 
          className="absolute top-2 right-2 text-[#8197AD] text-sm border border-[#DEDEDE] rounded-lg px-3 py-1"
        >
          Change
        </button>
      )}

      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center">
          <img src={causeLogo} alt={causeName} className="w-12 h-12 rounded-full" />
          <img src={images.fund_coin_icon} alt="Fundr" className="w-12 h-12 rounded-full" />
        </div>

        <div className="flex items-center space-x-2 mt-2">
          <div className="flex items-center">
            <p className="text-xl font-bold">{donationAmount}</p>
            <span className="text-md ml-1">/mo</span>
            <img src={images.right_arrow_icon} alt="Fundcoin" className="w-8 h-8 mr-4 ml-4" />
            <img src={images.fund_coin_icon} alt="Fundcoin" className="w-4 h-4 mr-1" />
            <p className="text-xl font-bold">{formatFundcoinAmount(fundcoinEarned)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationSection;
