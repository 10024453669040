import React from 'react';

interface ReferralStatsCardProps {
  totalReferrals: number;
  totalDonations: number;
}

const ReferralStatsCard: React.FC<ReferralStatsCardProps> = ({ totalReferrals, totalDonations }) => {
  return (
    <div className="relative w-full h-28 rounded-2xl bg-gradient-to-r from-pink-500 to-orange-400 p-4 flex items-center">
      <div className="flex-1 flex flex-col justify-between items-start border-r border-white/40 border-r-2 pr-4 h-full">
        <div className="text-3xl text-white mb-2">{totalReferrals}</div>
        <div className="text-xs text-white mb-4">Total Active <br /> Referrals</div>
      </div>
      <div className="flex-1 flex flex-col justify-between items-start pl-4 h-full">
        <div className="text-3xl text-white mb-2">${totalDonations}</div>
        <div className="text-xs text-white mb-4">Combined Lifetime <br /> Donations by referrals</div>
      </div>
    </div>
  );
};

export default ReferralStatsCard;
