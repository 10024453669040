import React, { useState, useEffect } from 'react';
import images from 'src/assets/images';
import Loader from 'src/components/common/loader';

interface RewardPurchaseVerificationProps {
  onClose: () => void;
  onComplete: () => void;  
}

const RewardPurchaseVerification: React.FC<RewardPurchaseVerificationProps> = ({ onClose, onComplete }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      setTimeout(onComplete, 3000);
    }, 3000);

    return () => clearTimeout(timer); 
  }, [onComplete]);

  return (
    <div className="w-auto">
      <div
        className="fixed inset-0 z-10 bg-[#0A1E32] bg-opacity-70 max-w-[548px] mx-auto"
        style={{ backdropFilter: 'blur(3px)' }}
      />
      <div className="fixed inset-x-0 bottom-0 bg-[#0A1E32] bg-opacity-90 rounded-t-3xl z-40 h-[calc(70%-2rem)] max-w-[548px] w-full mx-auto p-4 sm:p-6">
        <div className="text-[#FFFFFF] text-opacity-50 text-lg">
          PURCHASING
        </div>
        <hr className="custom-dash-line" />
        
        <div className="flex justify-center items-center mt-28">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="relative text-center">
              <div className="flex items-center justify-center mb-6">
                <div className="circle-animation"></div>
                <div className="circle-animation delay-1"></div>
                <div className="circle-animation delay-2"></div>
                <img src={images.success_icon_2} alt="Success" className="w-28 h-28 mx-auto" />
              </div>
              <h2 className="text-3xl font-semibold text-[#FF6B6B] mb-2 relative z-10">
                Success
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RewardPurchaseVerification;
