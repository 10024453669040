import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RouterWrapper from 'src/providers/routerProvider';
import SplashScreen from 'src/pages/Intro-Screens/splash';
import EmailInputScreen from 'src/pages/Signup/screens/emailInput';
import NameInputScreen from 'src/pages/Signup/screens/nameInput';
import PhoneNumberPasswordScreen from 'src/pages/Signup/screens/phone&passwordInput';
import SMSVerificationScreen from 'src/pages/Signup/screens/otpInput';
import OtpVerification from 'src/pages/Signup/screens/otpVerification';
import CongratulationsScreen from 'src/pages/Signup/screens/congratulations';
import IntroScreenOne from 'src/pages/Intro-Screens/intro-one';
import IntroScreenTwo from 'src/pages/Intro-Screens/intro-two';
import IntroScreenThree from 'src/pages/Intro-Screens/intro-three';
import IntroScreenFour from 'src/pages/Intro-Screens/intro-four';
import PasswordScreen from 'src/pages/Login/screens/password';
import ProtectedRoute from './protected/protectedRoute';
import PublicRoute from './public/publicRoute';
import Dashboard from 'src/pages/dashboard/screens/dashboard';
import Rewards from 'src/pages/rewards/rewards';
import Causes from 'src/pages/causes/causes';
import Wallet from 'src/pages/wallet/wallet';
import Refer from 'src/pages/refer/refer';
import Layout from 'src/components/common/layout';
import CauseDetail from 'src/pages/causes/causeDetail';
import RewardDetail from 'src/pages/rewards/rewardDetail';
import SelectCause from 'src/pages/Signup/screens/selectcause/selectCause';
import SelectCauseTwo from 'src/pages/Signup/screens/selectcause/selectCause-two';
import ChooseDonation from 'src/pages/Signup/screens/causedonation/chooseDonation';
import PaymentReceipt from 'src/pages/Signup/screens/causedonation/paymentReceipt';
import PaymentSuccess from 'src/pages/Signup/screens/causedonation/paymentSuccess';
import OnboardingComplete from 'src/pages/Signup/screens/causedonation/OnboardingComplete';
import UserReferralSignup from 'src/pages/Signup/screens/userReferralSignup';
import Settings from 'src/pages/settings/screens/setting';
import Profile from 'src/pages/settings/screens/profile';
import PrivacyPolicy from "../pages/privacy";
import TermsAndConditions from "../pages/terms";
import HelpPage from "../pages/help";
import Notifications from 'src/pages/settings/screens/notifications';
import DonationHistory from 'src/pages/settings/screens/donationHistory';
import ChangeDonation from 'src/pages/settings/screens/changeDonation';
const App: React.FC = () => {
  return (
    <RouterWrapper>
      <Layout>
        <Routes>
          <Route path="/" element={<PublicRoute element={<SplashScreen />} />} />
          <Route path="/introduction-one" element={<PublicRoute element={<IntroScreenOne />} />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/introduction-two" element={<PublicRoute element={<IntroScreenTwo />} />} />
          <Route path="/introduction-three" element={<PublicRoute element={<IntroScreenThree />} />} />
          <Route path="/introduction-four" element={<PublicRoute element={<IntroScreenFour />} />} />
          <Route path="/signup" element={<PublicRoute element={<UserReferralSignup />} />} />
          <Route path="/signup-cause" element={<PublicRoute element={<UserReferralSignup />} />} />
          <Route path="/email" element={<PublicRoute element={<EmailInputScreen />} />} />
          <Route path="/name" element={<PublicRoute element={<NameInputScreen />} />} />
          <Route path="/phone-password" element={<PublicRoute element={<PhoneNumberPasswordScreen />} />} />
          <Route path="/sms-verification" element={<PublicRoute element={<SMSVerificationScreen />} />} />
          <Route path="/loading-verification" element={<PublicRoute element={<OtpVerification />} />} />
          <Route path="/password" element={<PublicRoute element={<PasswordScreen />} />} />
          <Route path="/congratulations" element={<ProtectedRoute element={<CongratulationsScreen />} isNewUserRoute />} />
          <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
          <Route path="/rewards" element={<ProtectedRoute element={<Rewards />} />} />
          <Route path="/causes" element={<ProtectedRoute element={<Causes />} />} />
          <Route path="/cause-detail/:id" element={<ProtectedRoute element={<CauseDetail />} />} />
          <Route path="/reward-detail/:id" element={<ProtectedRoute element={<RewardDetail />} />} />
          <Route path="/wallet" element={<ProtectedRoute element={<Wallet />} />} />
          <Route path="/refer" element={<ProtectedRoute element={<Refer />} />} />
          <Route path="/select-cause" element={<ProtectedRoute element={<SelectCause />} isNewUserRoute />} />
          <Route path="/select-cause-two" element={<ProtectedRoute element={<SelectCauseTwo />} isNewUserRoute />} />
          <Route path="/choose-donation-amount" element={<ProtectedRoute element={<ChooseDonation />} allowBothUserTypes />} />
          <Route path="/cause-payment-receipt" element={<ProtectedRoute element={<PaymentReceipt />} allowBothUserTypes />} />
          <Route path="/cause-payment-success/:sessionId" element={<ProtectedRoute element={<PaymentSuccess />} allowBothUserTypes />} />
          <Route path="/onboarding-complete" element={<ProtectedRoute element={<OnboardingComplete />} allowBothUserTypes />} />
          <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />
          <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
          <Route path="/notifications" element={<ProtectedRoute element={<Notifications />} />} />
          <Route path="/donation-history" element={<ProtectedRoute element={<DonationHistory />} />} />
          <Route path="/change-donation" element={<ProtectedRoute element={<ChangeDonation />} />} />
        </Routes>
      </Layout>
    </RouterWrapper>
  );
};

export default App;
