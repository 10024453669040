import React from 'react';

interface RedemptionFeesCardProps {
  redemptionFee: string;
  taxesAndFees: string;
}

const RedemptionFeesCard: React.FC<RedemptionFeesCardProps> = ({ redemptionFee, taxesAndFees }) => {
  // Convert strings to numbers and calculate total cost
  const totalCost = parseFloat(redemptionFee) + parseFloat(taxesAndFees);

  return (
    <div className="bg-white rounded-lg p-4 w-full mt-2">
      <div className="flex justify-between items-center border-t-2 border-b-2 border-[#E5EAEF] py-2">
        <span className="text-md text-[#8197AD]">Redemption Fee</span>
        <span className="text-md text-black">${redemptionFee}</span>
      </div>
      <div className="flex justify-between items-center border-b-2 border-[#E5EAEF] py-2">
        <span className="text-md text-[#8197AD]">Taxes & Fees</span>
        <span className="text-md text-black">${taxesAndFees}</span>
      </div>
      <div className="flex justify-between items-center py-2 border-b-2 border-[#E5EAEF] py-2">
        <span className="text-lg font-bold text-[#3D86FF]">Total Cost</span>
        <span className="text-lg font-bold text-[#3D86FF]">${totalCost.toFixed(2)}</span>
      </div>
    </div>
  );
};

export default RedemptionFeesCard;
