import { postFile } from "../request";
import { API_URLS } from "src/constants/apiUrls";


export const uploadProfileImage = async (param: any) => {
    try {
      const data = await postFile(API_URLS.uploadProfileImage, param, {
      headers: {
        'Content-Type': 'multipart/form-data', 
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};