import React from 'react';
import RewardInfo from 'src/components/reward/rewardPurchaseModal/rewardInfo';
import RedemptionSteps from 'src/components/reward/rewardPurchaseModal/redemptionSteps';
import ContactSupport from 'src/components/reward/rewardPurchaseModal/contactSupport';
import images from 'src/assets/images';

interface RedemptionStep {
  heading: string;
  description: string;
}

interface RewardPurchaseCompleteProps {
  onClose: () => void;
  reward: {
    title: string;
    categoryName: string;
    image1Url: string;
    rewardWebsiteUrl: string;
    redemptionSteps?: RedemptionStep[]; // Optional in case it's null or undefined
  };
}

const RewardPurchaseComplete: React.FC<RewardPurchaseCompleteProps> = ({ onClose, reward }) => {
  const initialStep = {
    number: 1,
    heading: 'Redeem Reward',
    description: 'Your reward certificate has been sent to your email.',
    completed: true,
  };

  const steps = [
    initialStep,
    ...(reward.redemptionSteps && Array.isArray(reward.redemptionSteps)
      ? reward.redemptionSteps.map((step, index) => ({
          number: index + 2,
          heading: step.heading,
          description: step.description,
          completed: false,
        }))
      : []),
  ];

  return (
    <div className="w-auto">
      <div
        className="fixed inset-0 z-10 bg-[#0A1E32] bg-opacity-70 max-w-[548px] mx-auto"
        style={{ backdropFilter: 'blur(3px)' }}
      />
      <div className="fixed inset-x-0 bottom-0 bg-[#0A1E32] bg-opacity-90 z-40 h-[100%] max-w-[548px] w-full mx-auto p-4 sm:p-6 overflow-hidden">
        <button className="absolute top-5 right-6 z-50 bg-transparent" onClick={onClose}>
          <img src={images.close_icon} alt="Close" className="w-4 h-4" />
        </button>
        <div className="relative text-center mb-4">
          <h2 className="text-lg font-normal text-white">Reward Redeemed</h2>
          <p className="text-sm text-white mt-2">
            Check your email for your reward certificate and redemption instructions.
          </p>
        </div>

        <div className="flex flex-col space-y-6 overflow-y-auto h-full pb-14 scrollbar-hide">
          <RewardInfo
            title={reward.title}
            categoryName={reward.categoryName}
            imageUrl={reward.image1Url}
            websiteUrl={reward.rewardWebsiteUrl}
          />

          <RedemptionSteps steps={steps} />
          <ContactSupport />
        </div>
      </div>
    </div>
  );
};

export default RewardPurchaseComplete;
