import React, { useState, useEffect } from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import images from 'src/assets/images';
import Button from 'src/components/common/button';
import DonationRecieptCard from 'src/components/chooseDonation/donationReceiptCard';
import {
  createCheckoutSession,
  checkStripePaymentMethod,
  createCauseSubscription,
  updatePaymentMethod,
  updateCauseSubscription
} from 'src/services/payment/api';
import { fetchCause } from 'src/services/cause/api';
import { useAuth } from 'src/contexts/authContext';
import { toast } from 'react-toastify';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY || "0");
const causeproportionalAmount: number = parseFloat(process.env.REACT_APP_CAUSE_COIN_PROPORTIONAL_AMOUNT || "0");
const appproportionalAmount: number = parseFloat(process.env.REACT_APP_FUNDR_COIN_PROPORTIONAL_AMOUNT || "0");

const PaymentReceipt: React.FC = () => {
  const { user, selectedCauseId, donationAmount, appDonationAmount, setCoinsEarned }: any = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cause, setCause] = useState<any>(null);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [coinsEarned, setCoinsEarnedState] = useState(0);
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [showUpdateCardButton, setShowUpdateCardButton] = useState(false); // To show the update card button
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchCauseDetails = async () => {
      if (selectedCauseId) {
        try {
          const causeData = await fetchCause({ id: selectedCauseId });
          setCause(causeData);
        } catch (error) {
          console.error("Failed to fetch cause details:", error);
        }
      }
    };

    fetchCauseDetails();
  }, [selectedCauseId]);

  useEffect(() => {
    const checkPaymentMethod = async () => {
      try {
        const result: any = await checkStripePaymentMethod({ userId: user.id });
        if (result && result.hasPaymentMethods) {
          setHasPaymentMethod(true);
          if (result.defaultPaymentMethod) {
            setPaymentMethodId(result.defaultPaymentMethod.id);
          } else if (result.paymentMethods.length > 0) {
            setPaymentMethodId(result.paymentMethods[0].id);
          }
        }
      } catch (error) {
        console.error("Failed to check payment methods:", error);
      }
    };

    checkPaymentMethod();
  }, [user.id]);

  useEffect(() => {
    const calculateCoins = () => {
      const causeCoins = donationAmount * causeproportionalAmount;
      let appCoins = appDonationAmount * appproportionalAmount;
      if (appDonationAmount === 0.50) {
        appCoins = appDonationAmount * 100;
      }
      const totalCoins = causeCoins + appCoins;
      setCoinsEarned(totalCoins);
      setCoinsEarnedState(totalCoins);
    };

    calculateCoins();
  }, [donationAmount, appDonationAmount, setCoinsEarned]);

  const handleBack = () => {
    navigate('/choose-donation-amount');
  };

  const handleRedirectToBillingPortal = async () => {
    setLoading(true);
    try {
      const billingPortalUrl: any = await updatePaymentMethod({ userId: user.id });
      window.location.href = billingPortalUrl;
    } catch (error) {
      toast.error('Failed to redirect to billing portal.');
      setLoading(false);
    }
  };

  const handleCheckout = async () => {
    setLoading(true);
    setShowUpdateCardButton(false);
    const isUpdate = searchParams.get('update');
    if(!!isUpdate) {
      const totalAmount = donationAmount + appDonationAmount;
      const updateSubscription: any = await updateCauseSubscription({
        causeId: selectedCauseId,
        userId: user.id,
        amount: totalAmount,
      })
      setLoading(false);
      if(updateSubscription) {
        toast.success('subscription updated successfully.');
        setTimeout(() => {
          navigate('/profile')
        }, 3000)
      } else {
        toast.error('Something went wrong. Please try again letter');

      }
    } else {
      try {
        const stripe = await stripePromise;
        const totalAmount = donationAmount + appDonationAmount;

        if (hasPaymentMethod) {
          const subscriptionData: any = await createCauseSubscription({
            userId: user.id,
            amount: totalAmount,
            causeId: selectedCauseId,
            coinsEarned: coinsEarned,
            paymentMethodId: paymentMethodId,
          });

          if (subscriptionData.requiresAction || subscriptionData.status === 'incomplete') {
            const {error} = await stripe!.confirmCardPayment(subscriptionData.paymentIntentClientSecret);

            if (error) {
              if (error.payment_intent && error.payment_intent.last_payment_error) {
                const declineCode = error.payment_intent.last_payment_error.decline_code;
                if (declineCode === 'insufficient_funds') {
                  toast.error('Your card has insufficient funds. Please update your payment method.');
                  setShowUpdateCardButton(true); // Show the button to update card details
                  setLoading(false);
                  return;
                } else {
                  console.error('Payment failed with error:', error.payment_intent.last_payment_error.message);
                }
              }
              setLoading(false);
              return;
            }
            navigate(`/cause-payment-success/${subscriptionData.id}`);
          } else if (subscriptionData.status === 'active') {
            navigate(`/cause-payment-success/${subscriptionData.id}`);
          } else {
            console.error('Failed to create subscription:', subscriptionData);
            setLoading(false);
          }
        } else {
          const data: any = await createCheckoutSession({
            userId: user.id,
            amount: totalAmount,
            causeId: selectedCauseId,
            coinsEarned: coinsEarned,
          });

          const {error} = await stripe!.redirectToCheckout({sessionId: data.id});

          if (error) {
            console.error('Stripe Checkout error:', error);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error('Error processing checkout or subscription:', error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="p-4 relative">
      <div className="absolute top-4 sm:top-6 flex justify-between items-center w-full">
        <img
          src={images.leftarrow}
          alt="Back"
          className="w-4 h-4 sm:w-6 sm:h-6 cursor-pointer mt-3"
          onClick={handleBack}
        />
      </div>

      <div className="mt-12 sm:mt-16 w-full max-w-md text-left">
        <h1 className="text-2xl sm:text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
          Way to go, {user.firstName || 'there'}
        </h1>
        <p className="text-sm sm:text-md mb-4 text-[#1E1E1E]">
          Next let’s complete your donation by sending it to your cause: <strong>{cause?.name || "Loading..."}</strong>.
        </p>
      </div>

      <div className="mt-6 sm:mt-8">
        <DonationRecieptCard
          causeWallpaper={cause?.causeProfileUrl || images.cause_logo}
          hubDonation={donationAmount}
          causeName={cause?.name}
          fundrDonation={appDonationAmount}
          transactionFee={(donationAmount + appDonationAmount) * 0.029 + 0.30}
          userId={user.id}
          stripeCustomerId={user.stripeCustomerId}
        />
      </div>

      <div className="mt-4">
        {!showUpdateCardButton ? (
          <Button onClick={handleCheckout} disabled={loading}>
            {loading ? 'Processing...' : hasPaymentMethod ? 'Submit my donation' : 'Add my Payment Method'}
          </Button>
        ) : (
          <Button onClick={handleRedirectToBillingPortal} disabled={loading}>
            Update Card Details
          </Button>
        )}
      </div>
    </div>
  );
};

export default PaymentReceipt;
