import React from 'react';

interface CauseCardProps {
  causeWallPaper: string;
  causeLogo: string;
  causeName: string;
  causeMission: string;
  buyersCount: number;
  backgroundColor?: string;
  onClick: () => void;
}

const CauseCard: React.FC<CauseCardProps> = ({
  causeWallPaper,
  causeLogo,
  causeName,
  causeMission,
  buyersCount,
  backgroundColor = '#FFFFFF', // Default to white
  onClick,
}) => {
  const isBluishBackground = backgroundColor === '#00BCD5'; // Check for bluish background
  const textColor = isBluishBackground ? 'text-white' : 'text-black'; // Adjust text color
  const badgeTextColor = isBluishBackground ? 'text-black' : 'text-white'; // Change badge text color
  const badgeBgColor = isBluishBackground ? 'bg-white' : 'bg-black'; // Change badge background color

  return (
    <div
      onClick={onClick}
      className="rounded-xl shadow-lg overflow-hidden flex relative cursor-pointer w-full h-44"
      style={{
        backgroundColor, // Directly use the backgroundColor prop
      }}
    >
      {/* Image Section */}
      <div className="relative w-1/3">
        <img
          src={causeWallPaper}
          alt={causeName}
          className="w-full h-full object-cover"
        />
      </div>

      {/* Logo Positioned Outside the Image */}
      <div className="absolute left-[calc(33%-2rem)] top-1/3 transform -translate-y-1/2 flex items-center justify-center w-16 h-16 bg-white p-1 rounded-full shadow-md">
        <img
          src={causeLogo}
          alt={`${causeName} logo`}
          className="w-full h-full object-cover rounded-full"
        />
      </div>

      {/* Content Section */}
      <div className={`flex flex-col justify-between w-2/3 p-3 ${textColor}`}>
        <div className="flex-1 overflow-hidden py-6 px-8">
          <h3
            className="text-lg font-bold leading-tight"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {causeName}
          </h3>
          <p className="text-sm mt-1 truncate">{causeMission}</p>
        </div>

        {/* Footer Section */}
        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center">
            <div
              className={`flex items-center justify-center text-xs font-medium rounded-full ${badgeTextColor} ${badgeBgColor}`}
              style={{
                width: '40px',
                height: '40px',
              }}
            >
              {buyersCount}
            </div>
            <span className="ml-2 text-xs">
              Others are <br />
              donating
            </span>
          </div>
          <button
            className="bg-[#3D86FF] text-white text-xs font-bold py-2 px-3 rounded-xl"
          >
            Donate now
          </button>
        </div>
      </div>
    </div>
  );
};

export default CauseCard;
