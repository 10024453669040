import React from 'react';
import images from "../assets/images";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-gray-100 text-gray-800 min-h-screen p-6 max-h-full overflow-y-auto">
            <div className="max-w-4xl mx-auto p-2 py-8">
                <button
                    onClick={() => navigate(-1)}
                    className="absolute top-6 left-6 z-20 bg-white bg-opacity-60 text-gray-900 p-3 rounded-full shadow-md hover:bg-gray-200 focus:outline-none"
                >
                    <img src={images.leftarrow} alt="Back" className="w-3 h-3"/>
                </button>

                <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
                <p className="text-sm text-gray-600 mb-4">Effective Date: October 16, 2024</p>

                <h2 className="text-2xl font-semibold mb-3">Introduction</h2>
                <p className="mb-4">
                    At Fundr, we are committed to protecting your privacy and ensuring that your personal information is
                    handled in a safe and responsible manner. This Privacy Policy explains how we collect, use,
                    disclose, and protect your information when you use our app. By using Fundr, you agree to the
                    practices described in this policy.
                </p>

                <h2 className="text-xl font-semibold mb-2">1. Information We Collect</h2>
                <p className="mb-4">
                    We collect various types of information from you to enhance your experience, improve our services,
                    and ensure the app functions properly.
                </p>

                <h3 className="text-lg font-semibold mb-2">1.1 Information You Provide Directly</h3>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>Personal Information:</strong> When you sign up or interact with Fundr, we may ask for
                        your name, email address, phone number, payment information, and other identifiable details.
                    </li>
                    <li><strong>Donation Information:</strong> We collect data about your donations, including amounts,
                        frequencies, and associated nonprofits.
                    </li>
                    <li><strong>Profile Information:</strong> You may choose to provide additional information such as a
                        profile photo or preferences related to nonprofits and rewards.
                    </li>
                </ul>

                <h3 className="text-lg font-semibold mb-2">1.2 Automatically Collected Information</h3>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>Device Information:</strong> We collect data about the device you use to access Fundr,
                        including hardware models, operating systems, unique device identifiers, mobile network
                        information, and IP addresses.
                    </li>
                    <li><strong>Usage Information:</strong> We collect information on how you interact with the app,
                        including pages visited, features used, time spent on different screens, and donation history.
                    </li>
                    <li><strong>Location Information:</strong> With your consent, we may collect location data to offer
                        location-specific deals and services.
                    </li>
                </ul>

                <h3 className="text-lg font-semibold mb-2">1.3 Cookies and Tracking Technologies</h3>
                <p className="mb-4">
                    We use cookies, pixels, and similar technologies to track your activity on our app and website to
                    provide a personalized experience and for analytics purposes. You can manage cookie settings through
                    your browser, though some features may not function properly if cookies are disabled.
                </p>

                <h2 className="text-xl font-semibold mb-2">2. How We Use Your Information</h2>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>To Provide and Improve the App:</strong> We use your information to deliver the services
                        you’ve requested, such as managing donations and providing rewards.
                    </li>
                    <li><strong>To Personalize Your Experience:</strong> We may tailor content, offers, and rewards
                        based on your preferences and behavior.
                    </li>
                    <li><strong>For Communication:</strong> We use your contact information to send important
                        notifications, updates, promotions, and relevant nonprofit campaigns. You can opt out of
                        promotional communications at any time.
                    </li>
                    <li><strong>To Ensure Security:</strong> We use your data to monitor for fraudulent activity and
                        ensure the security of our platform.
                    </li>
                    <li><strong>To Analyze and Improve Our Services:</strong> We use analytics to understand how users
                        interact with Fundr and to make improvements based on that data.
                    </li>
                    <li><strong>For Legal Compliance:</strong> We may use your information to comply with legal
                        obligations, including resolving disputes and enforcing our agreements.
                    </li>
                </ul>

                <h2 className="text-xl font-semibold mb-2">3. How We Share Your Information</h2>
                <p className="mb-4">We take your privacy seriously and will only share your information under the
                    following circumstances:</p>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>With Nonprofit Partners:</strong> We share your donation history and relevant profile
                        information with the nonprofits you support through Fundr.
                    </li>
                    <li><strong>With Service Providers:</strong> We may share information with trusted third-party
                        service providers who assist in the operation of our app (e.g., payment processors, analytics
                        providers).
                    </li>
                    <li><strong>For Legal Reasons:</strong> We may share your information if required to do so by law,
                        such as to comply with a subpoena or to protect the rights and safety of Fundr, its users, and
                        the public.
                    </li>
                    <li><strong>With Your Consent:</strong> We may share information with other third parties when you
                        provide consent, such as opting into third-party reward programs.
                    </li>
                </ul>

                <h2 className="text-xl font-semibold mb-2">4. Data Retention</h2>
                <p className="mb-4">
                    We retain your personal data for as long as necessary to provide the services, comply with legal
                    obligations, resolve disputes, and enforce our agreements. If you delete your account or request the
                    deletion of your data, we will remove your information from our active databases, though we may
                    retain certain information for legal compliance or other legitimate purposes.
                </p>

                <h2 className="text-xl font-semibold mb-2">5. Security of Your Information</h2>
                <p className="mb-4">
                    We take reasonable measures to protect your information from unauthorized access, alteration,
                    disclosure, or destruction. However, no method of transmission over the internet is entirely secure,
                    and we cannot guarantee the absolute security of your information.
                </p>

                <h2 className="text-xl font-semibold mb-2">6. Your Rights and Choices</h2>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>Access and Correction:</strong> You can access and update your personal information
                        directly in the app or by contacting us.
                    </li>
                    <li><strong>Deletion:</strong> You can request that we delete your account and personal data. We
                        will comply with your request unless we need to retain certain information for legal or
                        operational purposes.
                    </li>
                    <li><strong>Opting Out:</strong> You can opt out of receiving promotional communications from us by
                        following the instructions in the communication or by contacting us. You cannot opt out of
                        essential service communications (e.g., donation confirmations, legal notices).
                    </li>
                    <li><strong>Cookies and Tracking:</strong> You can manage your cookie preferences through your
                        browser settings.
                    </li>
                </ul>

                <h2 className="text-xl font-semibold mb-2">7. Third-Party Links</h2>
                <p className="mb-4">
                    Our app may contain links to third-party websites or services, including reward partners and
                    nonprofit sites. We are not responsible for the privacy practices or content of these third parties.
                    We encourage you to read the privacy policies of any third-party site you visit.
                </p>

                <h2 className="text-xl font-semibold mb-2">8. Children’s Privacy</h2>
                <p className="mb-4">
                    Fundr is not intended for use by children under the age of 13. We do not knowingly collect personal
                    information from children under 13. If we become aware that a child under 13 has provided us with
                    personal information, we will take steps to delete such data.
                </p>

                <h2 className="text-xl font-semibold mb-2">9. International Data Transfers</h2>
                <p className="mb-4">
                    Your personal information may be transferred to and processed in countries outside your own. By
                    using Fundr, you consent to such transfers. We ensure that any cross-border data transfers comply
                    with applicable laws and maintain adequate safeguards.
                </p>

                <h2 className="text-xl font-semibold mb-2">10. Changes to This Privacy Policy</h2>
                <p className="mb-4">
                    We may update this Privacy Policy from time to time to reflect changes in our practices or for other
                    operational, legal, or regulatory reasons. We will notify you of any significant changes by posting
                    the updated policy on our app and website. Continued use of the app after changes constitutes your
                    acceptance of the updated policy.
                </p>

                <h2 className="text-xl font-semibold mb-2">11. Contact Us</h2>
                <p className="mb-4">
                    If you have any questions or concerns about this Privacy Policy or how we handle your personal
                    information, please contact us at:
                </p>
                <p className="font-semibold">
                    Email: <a href="mailto:pri14007@gmail.com"
                              className="text-blue-600 underline">pri14007@gmail.com</a>
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
