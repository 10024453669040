import React from 'react';
import images from 'src/assets/images';

interface RewardInfoProps {
  title: string;
  imageUrl: string;
  categoryName: string;
  websiteUrl: string;
}

const RewardInfo: React.FC<RewardInfoProps> = ({ title, imageUrl, categoryName, websiteUrl }) => {
  return (
    <div className="w-full bg-white rounded-2xl flex flex-col h-full">
      <div className="relative flex-1 flex items-center justify-center overflow-hidden rounded-t-2xl">
        <img
          src={imageUrl || images.reward_wallpaper}
          alt={title}
          className="w-full max-h-40 object-cover"
        />
      </div>
      <div className="p-4 flex flex-col justify-between">
        <div>
          <h3
            className="text-sm font-normal mb-1"
            style={{
              background: 'linear-gradient(to bottom, #EC4899, #FB923C)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {categoryName || 'Category Name'}
          </h3>
          <h3 className="mt-1 text-lg font-bold text-gray-800">{title}</h3>
        </div>
        <button
          onClick={() => window.open(websiteUrl, '_blank')}
          className="w-full mt-4 bg-gradient-to-r from-pink-500 to-orange-400 text-white py-2 rounded-lg"
        >
          VISIT WEBSITE & USE CODE
        </button>
      </div>
    </div>
  );
};

export default RewardInfo;
