import React from 'react';

const ContactSupport: React.FC = () => {
  return (
    <div className="mt-8 border border-[#FFFFFF] border-opacity-20 rounded-xl p-4 bg-[#0A1E32]">
      <p className="text-sm text-[#FFFFFF] text-opacity-50 text-center mb-4">Are you having any issue with your offer?</p>
      <button className="mx-auto block bg-[#112D44] text-[#3D86FF] text-sm py-2 px-4 rounded-xl">
        Please contact us at fundrteam@gmail.com
      </button>
    </div>
  );
};

export default ContactSupport;
