import React from 'react';

interface ProgressBarProps {
  percentage: number;
  height?: string;
  filledColor?: string;
  backgroundColor?: string;
  className?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  height = '8px', 
  filledColor = 'linear-gradient(to right, #EC4899, #FB923C)', 
  backgroundColor = '#E5E7EB', 
  className = '',
}) => {

  const cappedPercentage = Math.min(percentage, 100);

  return (
    <div
      className={`relative w-full rounded-full ${className}`}
      style={{
        height,
        backgroundColor,
      }}
    >
      <div
        className="absolute top-1/2 transform -translate-y-1/2 rounded-full"
        style={{
          width: `${cappedPercentage}%`, // Use cappedPercentage to cap it at 100%
          height: `calc(${height} + ${height})`, 
          background: filledColor,
        }}
      />
    </div>
  );
};

export default ProgressBar;
